
	// Global

	// Setup

	html{ font-size:#{$fontbase}px; }

	html,body{
		font-family:$main;
		// font-weight:$f-normal;  
		color:$gray_dark;
		width:100%;

		font-size:1rem;
		*, *:focus{
			outline:none!important;
			// Better Font Rendering
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}
	} // body  
  
	//

	.no-anim{
		transition-delay:0!important;
		transition:none!important;
	}

	$ease-in : cubic-bezier(0.430, 0.045, 0.100, 0.950);


	.to-load{

		&.opacity{
			opacity:0; 
		} 

 		transition:transform .0s, opacity .0s;
 		transition-duration: 1s, 1s;
		
		
		// Case study : Lists in Sass
		$durations : (2.3s, 1.5s); 
		// Indexes in Sass begin at 1. Go figure... 
		$pointer : 1;

			// Loop through every item of the list
			@each $item in $durations {
			  /* Duration #{nth($durations, $pointer)} */
			  $pointer : $pointer + 1;
			}

		// ^^ The idea was to store two different durations.
		// and use one for transform, and one for opacity.
		// It would generate : transition-duration:$duration1, $duration2;
		// Awesome, but for the moment, it's a dead end.
		// Other most important features are waiting.
		
		// Generate 100 delay classes.
		// One for every second and decimals

				@mixin timeGenerate($max, $property){

					$s : 0;

					$space : "-";

					// Loop X (ex : 10) time
					// I want X (ex : 10) of delays
					@while $s <= $max { 

						// Note : Until we find a way to attribute two different values
						//        to the properties (ex, one for transform, one for opacity),
						// 		  we will instead use the same for both, for lack of a better choice.
			 
						// Until we reach X (ex : 10), we also want decimals
						@if $s < $max {

						   // First Print a full number
						   &.#{$property}-#{$s}{
						  	transition-#{$property}:#{$s+'.0s'}, #{$s+'.0s'};
						   }  
						   // Then its decimals 
						   $decimale : 1;  
							@while $decimale < 10 {  
								&.#{$property}-#{$s}-#{$decimale}{
									// Visually indent decimals then drop the bomb
							  		#{'/*'+$space+'*/'}transition-#{$property}:#{$s+'.'+$decimale+'s'}, #{$s+'.'+$decimale+'s'};
							    } 
							    // Increment the decimal pointer
								$decimale : $decimale + 1; 
							}

						} // if

						@else{
						   &.#{$property}-#{$s}{
						  	transition-#{$property}:#{$s+'s'}, #{$s+'s'};
						   } 
						} // else
						
						// Keep s up to date. Increment its value
					  	$s: $s + 1;
					  	$space : $space + " ";


					} // while s < max
					
					// Reset 0, for further use in another loop.
					$s : 0;

				} // timeGenerate();

		// Finally output for 10s worth of duration and delay
		// Each property having 100 decimal classes to work with.
		// Should be enough uh ?
		@include timeGenerate(10, "duration");
		@include timeGenerate(10, "delay"); 

 
		// --------------------------------------------------------------
		
		&.top-to-bottom{
			transform:translate3d(0, -80px, 0);
			&.loaded{
				transform:translate3d(0px,0px,0);
			}
		}
		&.bottom-to-top{
			transform:translate3d(0, 80px, 0);
			&.loaded{
				transform:translate3d(0px,0px,0);
			}
		}
		&.left-to-right{
			transform:translate3d(-130px, 0, 0);
			&.loaded{
				transform:translate3d(0px,0px,0);
			}
		}
		&.right-to-left{
			transform:translate3d(130px, 0, 0);
			&.loaded{
				transform:translate3d(0px,0px,0);
			}
		}

		&.opacity{
			opacity:0;
			&.loaded{
				opacity:1;
			}
		} 

	}
	
	.container{
		max-width:1400px;
		@media #{$mq-xlg}{
			width:1600px;
		}
	} // container dimension - LG and XLG

	.demi-container{
		padding:0 15px;
		@media #{$mq-xlg}{
			width:calc(1400px / 2); 
		}
		@media #{$mq-lg}{
			width:calc(1200px / 2); 
		}
		@media #{$mq-md}{
			width:calc(1000px / 2); 
		}  
	}

	.container-fluid{
		width:100%;
		padding:0;
		.row{
			padding:0;
			margin:0;
		// 	>[class*="col-"]{
		// 		padding:0;
		// 	}
		// }
		// >[class*="col-"]{
		// 	padding:0;
		}
	}

	.full-height{
		min-height:700px;
		height:100vh;
		@media #{$mq-notdesk}{
			min-height:200px;
			height:auto;
			.js-map{
				height:100%;
			}
		}
	}

	.fixed{
		position:fixed;
		width:100%;
		top:0;
		left:0;
		z-index:100;
	}
	
	// NAV
	
	// *{
	// 	border:1px solid red;
	// }

	menu{
		padding:0;
		margin:0;
		transition:background .2s; 
		@media #{$mq-notdesk}{
			
			//background:$gray-lighter;

			nav{
				ul{
					opacity:0;
					a{
						padding:4vw; 
						font-size:5vw;
						
					}
				}
			}

			// When menu is open
			&.active{
				background:$activeColor!important;
				color:$white;
				padding-bottom:40px;
				.logo{
					svg{
						//border:1px solid red;
						path{
							fill:$white;
						}
					} // svg
				} // logo
				nav{
					ul{
						opacity:1;
						a{ 
							color:$white; 
						}
					}
				}
			} // .active
		} // mobile only
	} // menu


	nav{ 

		position:relative;  

		// .logo{
		// 	max-width:150px;
		// 	@media #{$mq-mob}{
		// 		max-width:30vw;
		// 		padding:3vh 0;
		// 	}
		// 	@media #{$mq-xs}{
		// 		max-width:17vw;
		// 	}
		// 	display:block;
		// 	@media #{$mq-desk}{
		// 		position:absolute;  
		// 		top:50%;
		// 		transform:translateY(-50%);
		// 	}
		// 	svg{
		// 		width:100%;
		// 		path{
		// 			fill:$gray-dark;
		// 		}
		// 		&:hover{
		// 			path{
		// 				fill:$activeColor;
		// 			}
		// 		}
		// 	}
		// } // .logo
		
		a{ 
			color:$gray;
			text-decoration: none; 
			display:block; 
			padding:2em 0; 
			&:hover, &:focus, &:active{
				text-decoration: none;
				color:$activeColor;
			}
		} // a 
		
		ul{
			@media #{$mq-notdesk}{
				// display:none;
				&.aligned{
					li{
						display:block;
						text-align:center;
					}
				}
			}
		}

		.mobile-actions{
			position:absolute; 
			top:37px;
			@media #{$mq-mob}{
				top:3.3vh;
			}
			right:0; 
			display:block;
			span{
				min-width:1em;
				min-height:1em;
				font-size:6.5vw;
				@media #{$mq-sm}{
					font-size:2rem;
				}
				@media #{$mq-xs}{
					font-size:4vw;
				}

				display:inline-block;

				// Spacing if more than one child in same line
				// All siblings following the first one will be spaced accordingly
				// Note : must be placed in a direct parent to work (div, p, span, whatever)
			  
 				&:not(:last-child){ 
 					margin-right:0.5em; 
 				} // first-child ~ &

			} 
			@media #{$mq-desk}{
				display:none;
			} 
		} // mobile-actions

		

	} // nav


	// HERO 

		.banner{

			background:lighten($black, 92%); 
			padding:6vh 0 4vh;
			position:relative;
			
			&.heading{
				padding-top:10vh; 

			}
			&.medium{
				padding:10vh 0 8vh;
				&.heading{
					padding-top:14vh;
				}
			}
			&.large{
				padding:20vh 0 18vh;
				&.heading{
					padding-top:24vh;
				}
				@media #{$mq-mob}{
					padding:10vh 0 8vh;
					&.heading{
						padding-top:15vh;
					}
				}
			}
			&.full{
				padding:0;
				position:relative;
				min-height:700px;
				height:100vh;
				.wrap{
					position:absolute;
					top:50%;
					left:50%;
					transform: translate(-50%, -50%);

				}
			}

			&.tile{
				padding:0;

			}

			.wrap{ 
				width:100%;
				position:absolute;
				top:50%;
				left:0;
				transform:translateY(-50%);
				&.full{
					height:100%;
					width:100%;
					z-index:0;
				}
			} // wrap

			h1{
				@media #{$mq-mob}{
					font-size:10vw;
				}
				@media #{$mq-xs}{
					font-size:5vw;
				}
				@media #{$mq-sm}{
					font-size:8vw;
				}
			}
			h2{
				@media #{$mq-mob}{
					font-size:8vw;
				}
				@media #{$mq-xs}{
					font-size:5vw;
				}
				@media #{$mq-sm}{
					font-size:5vw;
				}
			}
 
			menu{
				position:absolute;
				top:0px;
				left:50%;
				width:100%;
				background:none;
				transform:translateX(-50%);
				margin-bottom:5vh; 
				z-index:10;  
			}  

		}


	// SPACING utilities

	[class*="-spaced"]{
		
		&[class*="light"]{
			padding-top:20px;
			padding-bottom:20px;
			@media #{$mq-mob}{
				padding-top:20px;
				padding-bottom:20px;
			}
		}
		
		&[class*="medium"]{
			padding-top:50px;
			padding-bottom:50px;
			@media #{$mq-mob}{
				padding-top:35px;
				padding-bottom:35px;
			}
		}
	
		&[class*="large"]{
			padding-top:80px;
			padding-bottom:80px;
			@media #{$mq-mob}{
				padding-top:50px;
				padding-bottom:50px;
			}
		}

		&.no-top{
			padding-top:0;
		}
		&.no-bottom{
			padding-bottom:0;
		}

	} // [class -spaced]

	.padded{
		padding:30px;
	}


	// - Maps default behaviour

	.js-map{ 
		width:100%;
		min-height:300px;
		height:100%;
		background-color:lighten($gray-light, 10%);
		position:relative;
		&:before{
		    position:absolute;
		    content:"MAP";
		    opacity:0.5;
		    top:50%;
		    left:50%; 
		    transform: translate(-50%, -50%);
		}
	}


	// - Tiles layout

	
	[class*="tile-row"]{ 

		width:100%;

		@media #{$mq-desk}{
			display:table;
		} 

		[class*="col-"]{
			padding-left:0;
			padding-right:0;
			float:none;
		}

		&[class*="-m"]{
			.tile{
				vertical-align: middle;
			}
		}
		&[class*="-b"]{
			.tile{
				vertical-align: bottom;
			}
		}
	position:relative; 
		.tile{   
			
			min-height:200px;
			padding:0;
			
			@media #{$mq-desk}{
				display:table-cell; 
			} 
			@media #{$mq-notdesk}{
				min-height:0;
				display:table-row;
				//padding:30px;
			}

			@media #{$mq-sm}{
				min-height:400px;
			}
			@media #{$mq-xs}{
				min-height:300px;
			}
			@media #{$mq-xxs}{
				min-height:300px;
			}

			background-repeat:no-repeat;
			background-size:cover;
			background-position:center center;

			&.img-bg{ 
				overflow:hidden; 
				color:$white;
				&:before{
				    position:absolute;
				    content:"";
				    top:0;
				    left:0;
				    width:100%;
				    height:100%; 
				    opacity:0.40;
				    background:$black;
				    z-index:1;
				}
				.wrap{
					position:relative;
					z-index:2;
				}
			} // img-bg

		} // tile

	}


	// Footer styles - Temp

	footer{
		//background:$gray-light; 
		.credits{
			text-align:right;
			@media #{$mq-mob}{
				text-align:center; 
			}
		} 

		@media #{$mq-mob}{
			text-align:center; 
		}
	}






	/////////////////////////////
	// - General utilities




	// Add this toolbox

	.addthis_native_toolbox{
		display:inline-block;
		vertical-align: middle;
	}

	@media screen{
		.print{
			display:none;
		}
	}
	// @media print{

	// 	.noprint{
	// 		display:none!important;
	// 	}
		

	// 	a{
	// 		&:after{
	// 			display:none;
	// 		}
	// 	}
		
	// 	.map{
	// 		transform:none!important;
	// 		width:100%!important;
	// 		min-height:10cm;
	// 	}

	//     img {
	//         max-width: none !important;
	//     } 

	// 	.scroll{
	// 		display:none!important;
	// 	}

	// 	p, strong, a, em, table, tr, td, form * {
	// 		font-size:1rem;
	// 	}
	// 	h1 em{
	// 		font-size:1em;
	// 	}


	// 	form{
	// 		input{
	// 			border:1px solid $gray!important;
	// 			padding:10px 15px!important;
	// 		}
	// 		[class*="wrap"]{
	// 			margin-bottom:20px!important;
	// 			&[class*="select"]{
	// 				&:before{
	// 					display:none;
	// 				}
	// 			}
	// 			&[class*="radio"],&[class*="check"]{  
	// 				display:inline-block;
	// 				margin-right:15px;
	// 				vertical-align: middle;  
	// 				&[class*="radio"]{
	// 					label{
	// 						&:before{
	// 							border-radius:50%;
								
	// 						}
	// 					}
	// 				} 
	// 				input{
	// 					display:none;  
	// 					&:not(:checked) + label {  
	// 						&:before{
	// 							background:$activeColor;
	// 							border:1px solid $gray!important;
	// 						}
	// 					}
	// 					&:checked + label {  
	// 						&:before{
	// 							background:$activeColor;
	// 							border:10px solid $gray!important;
	// 						}
	// 					}
	// 				} 
	// 			}
	// 		}
	// 	}

	// 	blockquote{
	// 		border-top:none;
	// 		border-right:none;
	// 		border-bottom:none;
	// 	}

	// 	header{
	// 		text-align:center;
	// 		padding-top:1cm!important;
	// 		padding-bottom:1cm!important;
	// 		&.print{
	// 			.logo{
	// 				display:inline-block;
	// 				svg{
	// 					width:4cm;
	// 				}
	// 			}
	// 		}
	// 	}

	// 	menu{
	// 		display:none;
	// 	}

	// 	.row{
	// 		text-align:center;
	// 		font-size:0;
	// 	}

	// 	.editable{
	// 		*{
	// 			text-align:left!important;
	// 		}
	// 	}

	// 	// Page break
		
	// 	// [class*='col'], .row{
	// 	// 	page-break-after: always;
	// 	// }
	// 	// ul, li, p, a, strong, em, table, form{
	// 	// 	page-break-after: avoid;
	// 	// }

	// 	// Grid reset
		
	// 	$cols: 1;
	// 	$percent : "%"; 
							
	// 	[class*="col"]{ 
	// 		display:inline-block;
	// 		@while $cols < 13 { 
	// 		    $calc : ($cols / 12 * 100) - 1;
	// 		    &[class*="col-md-#{$cols}"]{ 
	// 		   		width: #{$calc}#{$percent};
	// 		   	} 
	// 		 	$cols: $cols + 1;
	// 		} 
	// 	}

	// 	[class*="spaced_"]{  
		  		
	//   		&[class*="element-wrap"]{
	// 			h2, h3{
	// 				font-size:1rem;
	// 			}
	// 			i{
	// 				display:none!important;
	// 			}
	//   		}
	// 	}
	// 	.feature{  
		  		
	//   		 	h2, h3{
	// 				font-size:1rem;
	// 			}
	// 			i{
	// 				display:none!important;
	// 			} 
	// 	}

	// } // print