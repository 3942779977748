@font-face {
  font-family: 'aco';
  src:  url('../fonts/aco.eot?e6r0ph');
  src:  url('../fonts/aco.eot?e6r0ph#iefix') format('embedded-opentype'),
    url('../fonts/aco.woff2?e6r0ph') format('woff2'),
    url('../fonts/aco.ttf?e6r0ph') format('truetype'),
    url('../fonts/aco.woff?e6r0ph') format('woff'),
    url('../fonts/aco.svg?e6r0ph#aco') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*="-icon-"]:before, {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aco' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1; 
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[class*="-icon-box"]:before {
  content: "\e900";
}
[class*="-icon-write"]:before {
  content: "\e901";
}
[class*="-icon-clock"]:before {
  content: "\e902";
}
[class*="-icon-reply"]:before {
  content: "\e903";
}
[class*="-icon-reply-all"]:before {
  content: "\e904";
}
[class*="-icon-forward"]:before {
  content: "\e905";
}
[class*="-icon-flag"]:before {
  content: "\e906";
}
[class*="-icon-search"]:before {
  content: "\e907";
}
[class*="-icon-trash"]:before {
  content: "\e908";
}
[class*="-icon-envelope"]:before {
  content: "\e909";
}
[class*="-icon-bubble"]:before {
  content: "\e90a";
}
[class*="-icon-bubbles"]:before {
  content: "\e90b";
}
[class*="-icon-user"]:before {
  content: "\e90c";
}
[class*="-icon-users"]:before {
  content: "\e90d";
}
[class*="-icon-cloud"]:before {
  content: "\e90e";
}
[class*="-icon-download"]:before {
  content: "\e90f";
}
[class*="-icon-upload"]:before {
  content: "\e910";
}
[class*="-icon-rain"]:before {
  content: "\e911";
}
[class*="-icon-sun"]:before {
  content: "\e912";
}
[class*="-icon-moon"]:before {
  content: "\e913";
}
[class*="-icon-bell"]:before {
  content: "\e914";
}
[class*="-icon-folder"]:before {
  content: "\e915";
}
[class*="-icon-pin"]:before {
  content: "\e916";
}
[class*="-icon-sound"]:before {
  content: "\e917";
}
[class*="-icon-microphone"]:before {
  content: "\e918";
}
[class*="-icon-camera"]:before {
  content: "\e919";
}
[class*="-icon-image"]:before {
  content: "\e91a";
}
[class*="-icon-cog"]:before {
  content: "\e91b";
}
[class*="-icon-calendar"]:before {
  content: "\e91c";
}
[class*="-icon-book"]:before {
  content: "\e91d";
}
[class*="-icon-map-marker"]:before {
  content: "\e91e";
}
[class*="-icon-store"]:before {
  content: "\e91f";
}
[class*="-icon-support"]:before {
  content: "\e920";
}
[class*="-icon-tag"]:before {
  content: "\e921";
}
[class*="-icon-heart"]:before {
  content: "\e922";
}
[class*="-icon-video-camera"]:before {
  content: "\e923";
}
[class*="-icon-trophy"]:before {
  content: "\e924";
}
[class*="-icon-cart"]:before {
  content: "\e925";
}
[class*="-icon-eye"]:before {
  content: "\e926";
}
[class*="-icon-cancel"]:before {
  content: "\e927";
}
[class*="-icon-chart"]:before {
  content: "\e928";
}
[class*="-icon-target"]:before {
  content: "\e929";
}
[class*="-icon-printer"]:before {
  content: "\e92a";
}
[class*="-icon-location"]:before {
  content: "\e92b";
}
[class*="-icon-bookmark"]:before {
  content: "\e92c";
}
[class*="-icon-monitor"]:before {
  content: "\e92d";
}
[class*="-icon-cross"]:before {
  content: "\e92e";
}
[class*="-icon-plus"]:before {
  content: "\e92f";
}
[class*="-icon-left"]:before {
  content: "\e930";
}
[class*="-icon-up"]:before {
  content: "\e931";
}
[class*="-icon-browser"]:before {
  content: "\e932";
}
[class*="-icon-windows"]:before {
  content: "\e933";
}
[class*="-icon-switch"]:before {
  content: "\e934";
}
[class*="-icon-dashboard"]:before {
  content: "\e935";
}
[class*="-icon-play"]:before {
  content: "\e936";
}
[class*="-icon-fast-forward"]:before {
  content: "\e937";
}
[class*="-icon-next"]:before {
  content: "\e938";
}
[class*="-icon-refresh"]:before {
  content: "\e939";
}
[class*="-icon-film"]:before {
  content: "\e93a";
}
[class*="-icon-home"]:before {
  content: "\e93b";
}

/// lol celebest les icons plus haut

[data-icon] {
  &:not([data-icon="SVG"]) {
    &:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Material Icons'!important;
    content: attr(data-icon);
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-feature-settings: 'liga';
    line-height: 1;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
  }
}

.material-icons {
    &:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Material Icons'!important;
    content: attr(data-icon);
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-feature-settings: 'liga';
    line-height: 1;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
}

[data-icon="SVG"] {
  position: relative;
  svg {
    fill: $white;
  }
}

.Icon {
  position:relative;
  min-width:1em;
  min-height:1em;
  &:before{
    position:absolute; 
    top:50%;
    transform: translateY(-50%); 
    color: currentColor; 
  }
  > svg {
    position: absolute;
    max-width: 1em;
    max-height: 1em;
    top:50%;
    left: 0;
    transform: translateY(-50%);
    #bg {
      path {
        fill: $white;
      }
    }
    #ic {
      path {
        fill: $activeColor;
      }
    }
  }

  &.bigger-icon {
    // display: inline-block;
    padding-right: 2.5em;
    &:before {
      font-size: 20px;
    }
  }
}

// center
.Icon-center {
  @extend .Icon;
  &:before{
    left:50%;
    transform: translate(-50%, -50%);
  }
  > svg {
    left: auto;
  }
}

// left
.Icon-left {
  @extend .Icon;
  padding-left:1.6em;
  &:before{
    left:0; 
  }
}

// right
.Icon-right {
  @extend .Icon;
  padding-right:1.6em;
  &:before{
    right:0; 
  }
  > svg {
    left: auto;
    right: 0!important;
  }
}
