@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400italic,700,700italic&display=swap');

// materials icons
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


$serif : 'Marcellus', Georgia, "Times New Roman", Times, serif;
$main: 'Montserrat', Helvetica, Arial, sans-serif;

$fw-normal: 400;
$fw-bold: 700;

// used in loops to generate media queries for helpers / modules
	$ListBreakpoint : (
		(null, 0),
	// min
		(XS, #{$scr-xs}, 'min-width'),
		(SM, #{$scr-sm}, 'min-width'),
		(MD, #{$scr-md}, 'min-width'),
		(LG, #{$scr-lg}, 'min-width'),
		(XLG, #{$scr-xlg}, 'min-width'),
	// max
		(XXSmax, #{$scr-xxs-max}, 'max-width'),
		(XSmax, #{$scr-xs-max}, 'max-width'),
		(SMmax, #{$scr-sm-max}, 'max-width'),
		(MDmax, #{$scr-md-max}, 'max-width'),
		(LGmax, #{$scr-lg-max}, 'max-width')
);

// MIXIN MEDIA QUERIES
@mixin media($breakpoint) {
	@if $breakpoint == "xsmall" {
		@media screen and (min-width: $scr-xs) {
			@content
		}
	}
	@if $breakpoint == "XS" {
		@media screen and (min-width: $scr-xs) {
			@content
		}
	}
	@else if $breakpoint == "small" {
		@media screen and (min-width: $scr-sm) {
			@content
		}
	}
	@else if $breakpoint == "SM" {
		@media screen and (min-width: $scr-sm) {
			@content
		}
	}
	@else if $breakpoint == "medium" {
		@media screen and (min-width: $scr-md) {
			@content
		}
	}
	@else if $breakpoint == "MD" {
		@media screen and (min-width: $scr-md) {
			@content
		}
	}
	@else if $breakpoint == "large" {
		@media screen and (min-width: $scr-lg) {
			@content
		}
	}
	@else if $breakpoint == "LG" {
		@media screen and (min-width: $scr-lg) {
			@content
		}
	}
	@else if $breakpoint == "xlarge" {
		@media screen and (min-width: $scr-xlg) {
			@content
		}
	}
	// max
	@else if $breakpoint == "MDmax" {
		@media screen and (max-width: $scr-md-max) {
			@content
		}
	}
	@else if $breakpoint == "SMmax" {
		@media screen and (max-width: $scr-sm-max) {
			@content
		}
	}
	@else if $breakpoint == "XSmax" {
		@media screen and (max-width: $scr-xs-max) {
			@content
		}
	}
	@else if $breakpoint == "XXSmax" {
		@media screen and (max-width: $scr-xxs-max) {
			@content
		}
	}
}


// COLORS
$white: #ffffff;
$black: #000000;

$charcoal: #363636;

$lighter-gray: #f8f8f8;
$light-gray: #c4c4c4;
$gray: #898988;
$grayDark: $charcoal;

$brown: #503529;
$dark-brown: #231E1B;
$darker-brown: #101010;
$light-brown: #B5A899;
$cream: #F8F6E6;

$blue: #1b365d;

$red: #bf0101;
$red2: #ed3333;

$activeColor: $blue;
$secondaryColor: $dark-brown;
$thirdColor: $cream;

$textColor: $darker-brown;

$mediumColor: $light-brown;
$borderColor: $dark-brown;
$lightColor: lighten($light-brown, 30%);
$splashColor: rgba($darker-brown, 0.25);
$splashTextColor: $white;

$letterspacing: 0.5px;


// SPACING
@mixin spacing($padding) {
	padding-top: $padding;
	padding-bottom: $padding;
}