

		// //////////////////////////////////////////////////////////////////////////
		// //////////////////////////////////////////////////////////////////////////

			// - Responsive witness

		     body{
		         &:before{
		                // display:none;
		                position:fixed; 
		                top:0;
		                left:50%;
		                transform: translateX(-50%);
		                background:$white; 
		                color:$black;
		                text-align:center;
		                min-width:40px;
		                font-weight:500;
		                z-index:1000000; // should be enough yo
		                opacity:0.5;
		                padding:10px 7px; 
		            } // before
		      } // body 

		    // - End Responsive witness

		    // - REALLY Small screen disclaimer

		    @media screen and (max-width:319px){
				body{
					&:after{
					    position:fixed;
					    padding:30px 15px;
					    line-height:1.2;
					    text-align:center;
					    content:"Votre écran n'est pas assez large. Veuillez pivoter votre appareil, ou consulter ce site sur un appareil plus récent.";
					    opacity:0.9;
					    top:0;
					    left:0;
					    z-index:1000000;
					    background:white;
					    width:100%;
					    height:100%; 
					} // after
				} // body
		    } // mq

		    // End - REALLY small screen disclaimer


		//////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////







