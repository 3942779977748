
	// UI - User interface elements

		
	*{
		-webkit-appearance: none!important; 
	}

	//  -
	//  UI 

		// Icon positionning system

		[class*='icon-']{ 

			position:relative;
			min-width:1em;
			min-height:1em;

			&:before{
			    position:absolute; 
			    top:50%;
			    transform: translateY(-50%); 
			    color:currentColor; 
			}
			
			&[class*="-c"]{ 
				&:before{
			    	left:50%;
			    	transform: translate(-50%, -50%);
			    }
			}
			&[class*="-l"]{
				padding-left:1.6em;
				&:before{
			    	left:0; 
			    }
			}
		 	&[class*="-r"]{
		 		padding-right:1.6em;
				&:before{
			    	right:0; 
			    }
			}
			
			// Specificities regarding some elements 
			// that could badly react to the icon (spacing related)

			&[class*="form-element"]{
				hr{
					position:absolute; 
					bottom:0;
					width:100%;
					left:0;
				}
				&[class*="-l"]{ 
					padding-left:1.6em;
					&:before{
				    	left:0; 
				    }
				}
			 	&[class*="-r"]{ 
			 		padding-right:1.6em;
					&:before{
				    	right:0; 
				    }
				}
				&.boxed{
					&[class*="-l"]{ 
						padding-left:1.6em;
						&:before{
					    	left:0.8em; 
					    }
					}
				 	&[class*="-r"]{ 
				 		padding-right:1.6em;
						&:before{
					    	right:0.8em; 
					    }
					}
				}
			} // form elements

		}
		
		// Error messages

		[class*="error"],[class*="error"] + label {  
			color:darken(red, 10%); 
		}

		[class*="valid"], [class*="valid"] + label{  
			color:$activeColor; 
		}
		
		label[class*="error"],
		label[class*="validated"]{
			text-transform: uppercase; 
			font-size:0.8rem; 
			letter-spacing:0.02em; 
		}

		// Links

			a{
				text-decoration: underline; 
				font-weight:$fw-bold; 
				color:$activeColor; 
				font-size:1rem;
				&:hover, &:hover, &:focus{
					color:currentColor;
					&.btn{
						text-decoration: none;
						&, &.alt, &.light{ color:$white; }
						&.greyed{ color:lighten($black, 66%); } 
					} 
				}
				:not(.editable) &{ 
					text-decoration: none;
					&:hover, &:active, &:focus{
						text-decoration:none;
					}
				}
			} // a

		// HR

			hr{
				border:none;
				height:1px;
				background:lighten($black, 80%);
				margin:2rem 0;
				&.medium{
					margin:3rem 0;
				}
				&.large{
					margin:4rem 0;
				}
			}

		// Headings

			h1, h2, h3, h4, h5, h6,
			.h1, .h2, .h3, .h4, .h5, .h6{
				font-weight:$f-bold; 
			}

			h1, .h1{
				@include rem(51); 
				margin-bottom:0.75em;
				margin-top:0;
			}
			h2, .h2{
				@include rem(37.5);
				margin-bottom:0.80em;
				margin-top:0;
			}
			h3, .h3{
				@include rem(31);
				margin-bottom:1em;
				margin-top:0;
			}
			h4, .h4{
				@include rem(23.5);
				margin-bottom:1em;
				margin-top:0;
			}
			h5, .h5{
				@include rem(21);
				margin-bottom:1em;
				margin-top:0;
				font-weight:$f-ultrabold;
			}
			h6, .h6{
				@include rem(18);
				margin-bottom:1em;
				margin-top:0;
				font-weight:$f-ultrabold;
			}

			// Margins top only in editable blocks
			.editable{
				h2{margin-top:0.80em;}
				h3, h4, h5, h6{margin-top:1em;}
			}

		// Paragraphs

			p, blockquote{
				font-size:1rem;
				line-height:1.57;
				margin-bottom:1.3em;
			}

		// BTNs
 	
 			.btn{ 

 				display:inline-block;
 				background:$activeColor;
 				color:$white;
 				// font-weight:$f-bold;
 				padding:7px 20px; 
 				border:2px solid transparent;
 				text-decoration: none;
 				vertical-align: middle;
 				font-size:1rem;
 				border-radius:3px;
			
				// Button states

 				&.alt{
 					background:$gray_dark;
 				} // alt
 				&.greyed{ 
 					background:lighten($black, 92%);
 					color:lighten($black, 66%);
 				} // greyed
 				&.light{
 					background:none;
 					color:$activeColor;
 					border-color:$activeColor;
 					&.alt{
 						color:lighten($black, 66%);
 						border-color:lighten($black, 66%);
 					}
 				} // light
				&.round{
					padding:0;
					border-radius:50%; 
					text-align:center;
				}
				&:empty{
					padding:0;
					height:2.5rem;
					width:2.5rem;
				}
 
				// Hover

				&:hover{
					// background:lighten($activeColor, 10%);
					color:$white;
					&.alt{
						color:$white;
						background:lighten($gray_dark, 10%);
					} // alt
					&.greyed{ 
						background:lighten($black, 87%);
					} // greyed
					&.light{
						background:$activeColor;
						color:$white;
						&.alt{
							background:lighten($black, 66%);
						}
					} // light
				} // hover

				// Spacing if more than one child in same line
				// All siblings following the first one will be spaced accordingly
				// Note : must be placed in a direct parent to work (div, p, span, whatever)
			 
 				&:first-child ~ & { 
 					// margin-left:0.5em; 
 				} // first-child ~ &

 			} // .btn


 		// Unordered (ul) and ordered (ol) lists
			 
 			ul,ol{   

 				&[class]{
					list-style-type:none;
					padding:0;
 				} // with class

 				// Style if no class is applied 
 				// (Usefull for styling ul, ol issed by wysiwygs)
 				&:not([class]){
 					padding-left:1.5rem;
 				} // class

				&.bordered{
					li:not(:last-child){
						border-bottom:1px solid lighten($black, 80%);
					}
				} // bordered

 				li{ 
 					padding-bottom:0.4em;
 					margin-bottom:0.4em;
 					&[class*='icon-']{
						&:before{
							top:0.15em;
							transform:none;
						}
					}
 				} // li

				&.aligned{
					margin:0;
					//display:inline-block;

					li{
						display:inline-block;

						margin:0;
						&:not(:last-child){
							margin-right:1em;
						}
						*{
							display:inline-block;
							vertical-align: middle;
						}
					}

					@media #{$mq-mob}{
						&.mob-to-col{
							li{
								display:block;
								margin:0;
							}
						}
					}
				}

 			} // ul, ol

		// Bloquotes

			blockquote{
				border:none;
				padding:0; 
				padding-left:1.2em;
				position:relative; 
				&:before{
				    position:absolute;
				    content:"";
				    top:0.35em;
				    height:calc(100% - 0.65em); 
				    left:0;
				    background:$gray_dark; 
				    // Variable bar width alongside font-size
				    width:0.2em; 
				} 
			}
 
		// Accordions

			.accordion{ 

				margin-bottom:2em;
				
				border-top:2px solid lighten($black, 80%);
				border-bottom:2px solid lighten($black, 80%);
				 

				.element{ 
					&:not(:last-child){
						border-bottom:2px solid lighten($black, 80%);
					}
					padding-top:1.3rem;
					padding-bottom:1.3rem;
					p:last-child{
						margin:0;
					}
					&.active{
						.title{
							&:before{
								color:$activeColor;
							}
						}
					}
				}
				.title{
					margin:0;
					
				}
				.content{
					margin-top:1.3rem;
					display:none;
				}
			}

		// UI DE CONTENU (wysiwyg styles) 
		
		  	.editable{
		  		
		  		// Wysiwygs

		  	} // editable 
		
		// Listing

			.list{
				display:block;
				text-decoration:none;
			}

			.thumb-wrap{
				img{
					width:100%;
				}
				&.spaced{
					margin-bottom:1.4rem;
				}

				@media #{$mq-mob}{ 
					margin-bottom:1.4rem;
				} 
				@media #{$mq-sm}{ 
					margin-bottom:1.4rem;
				} 
			}

			// article.list{
			// 	margin-bottom:40px;
			// }

			[class*="list"]{

				font-size:0;

				&.spaced{
					&:not(:last-child){
						margin-bottom:2rem;
					}
				}

				@media #{$mq-mob}{
					//text-align:center;
				}

				// - Thumb

				img{
					width:100%;
				} 
				// - Heading

				// :not(.editable){
				// 	h2, h3, h4, h5, h6{ 
				// 		margin-bottom:0.8em; 
				// 		@media #{$mq-xxs}{
				// 			font-size:7vw;
				// 		}
				// 		@media #{$mq-xs}{
				// 			font-size:5vw;
				// 		}
				// 		@media #{$mq-sm}{
				// 			font-size:4vw;
				// 		}
				// 		@media #{$mq-desk}{
				// 			font-size:1.5rem;
				// 		}
				// 		@media #{$mq-md}{
				// 			font-size:1.8vw;
				// 		}
						
				// 	}
				// }

				// wrap content

				.wrap{
					padding:0 15px;
				}
			
				// Colunn behaviour inside of list
				> [class*="col-"]{
					float:none; 
					font-size:1rem;
					display:inline-block; 
					vertical-align: top; // default alignment
					// @media #{$mq-mob}{
					// 	width:100%;
					// }
					// @media #{$mq-sm}{
					// 	width:100%;
					// } 
				}  

				// List column alignment

					// Top is the default value : no need for a suffix

					// Middle
					&[class*="st-m"]{
						& > [class*="col-"]{ vertical-align: middle; }
					}
					// Bottom
					&[class*="st-b"]{
						& > [class*="col-"]{ vertical-align: bottom; }
					}

				.gallery & {
					li{
						width:25%;
						@media #{$mq-notdesk}{
							width:50%;
							&:nth-child(n + 5){
								display:none;
							}
						}
					}
				}

			} // list



		// Tabs

		.tabs-wrap{ 
			//margin-bottom:40px;
			.nav{
				margin-bottom:2rem;
				li{
					padding:0;
					a{
						border-radius:0;
						text-decoration:none;
						color:$gray;
					}
					&.active{
						a{
							color:$activeColor;
							position:relative;
							// &:before{
							//     position:absolute;
							//     content:"";
							//     bottom:100%;
							//     left:-1px;
							//     width:calc(100% + 2px);
							//     height:2px;
							//     background:$activeColor; 
							// }
						}
					}
				}
			}
		}
 
		//  Breadcrumbs

			//
 
		//  Pagination 

			//
 
		//  Gallery

			//
		 
		//  Tables 

			// table{
			// 	border:1px solid red;
			// 	width:100%;
			// 	font-weight:$f-medium;
			// 	margin-bottom:30px;
			// 	th{
			// 		@include rem(11.5);
			// 		text-transform: uppercase;
			// 	}
			// 	td{
			// 		@include rem(13.5);
			// 	}
			// 	th, td{
			// 		padding:10px;
			// 		border:1px solid lighten($black, 80%); 
			// 		&:empty{
			// 			background:lighten($black, 95%);
			// 		}
			// 	} // th,td
			// } // table
 
		//  Forms
 
				label{
					font-weight:$f-normal;
					display:block;
					margin-bottom:1rem;
				} 

				[class*="form-element"]{
					
					margin-bottom:20px;
					position:relative;


					// Colored line at input focus
					.line{ 
						display:inline-block;
						top:calc(100% - 1px);
						z-index:1000;
						left:0;
						position:absolute;
						width:0%;
						height:1px;
						background:$activeColor;
						transition:all .2s;
					}
					input:focus ~ .line{
						width:100%;
					}

					&[class*="icon-"]{
						padding-right:0;
						padding-left:0;
					} 

					// HR visual reset
					hr{margin:0; width:100%;} 

					// Inputs
					input:not([type="radio"]),
					input:not([type="checkbox"]){ 
						display:block;
						width:100%;
						padding:0.8rem;
						background:none;
						border:none; 
					}

					// Radio and checkbox
					&[class*="radio"], 
					&[class*="checkbox"]{   
						&.boxed{
							padding:0.8em 0.8em 0.7em;
						} 
						display:inline-block;

						input{
							display:none;
						}

						label{
							margin-bottom:0;
							position:relative;
							padding-left:1.8em;
							&:before{
							    position:absolute;
							    content:"";
							    top:0.08em;
							    left:0;
							    background:$white;
							    border:1px solid lighten(gray, 25%);
							    border-radius:2px;
							    width:1.1em;
							    height:1.1em; 
							} 
						} // label

						&[class*="radio"]{
							label{
								&:before{
									border-radius:40px;
								}
							}
						}

						// Checked state 
						:checked + label{ 
							&:before{
								background:$activeColor;
								border-color:$activeColor;
							}
						}
						
						// Spacing if more than one child in same line
						// All siblings following the first one will be spaced accordingly
						// Note : must be placed in a direct parent to work (div, p, span, whatever)
						&:first-child ~ & {  
		 					margin-left:0.8em; 
		 				} // first-child ~ &

					} // Checkboxes and radios
					

					// Textareas
					textarea{
						padding:0.8rem;
						height:auto;
						min-height:100px;
						width:100%;
						background:none;
						border:none;
					}
					
					// Boxed
					&.boxed{
						background:lighten($black, 95%); 
					}
					
					// Specificity for elements not boxed
					// (spacing, etc)
					&:not(.boxed){
						input:not([type="radio"]),
						input:not([type="checkbox"]){
							padding-left:0;
							padding-right:0; 
						}
					} // not boxed

				} // form-element
 

	//  END UI 
	//  -