

		// //////////////////////////////////////////////////////////////////////////
		// //////////////////////////////////////////////////////////////////////////



		    @mixin responsiveTest ($region){

		      body{
		          position:relative; 
		            &:before{
		               display:none;
		                position:fixed;
		                content:$region;
		                top:0;
		                left:0;
		                // transform: translateX(-50%);
		                background:black; 
		                color:white;
		                text-align:center;
		                min-width:40px;
		                font-weight:500;
		                z-index:1000000;
		                opacity:0.2;
		                padding:10px 7px;
		            //    font-weight:600;
		            } // before
		      } // body

		    } // responsiveTest

		    @media screen and (max-width:319px){
				body{
					&:after{
					    position:fixed;
					    padding:30px 15px;
					    line-height:1.2;
					    text-align:center;
					    content:"Votre écran n'est pas assez large. Veuillez pivoter votre appareil, ou consulter ce site sur un appareil plus récent.";
					    opacity:0.9;
					    top:0;
					    left:0;
					    z-index:1000000;
					    background:white;
					    width:100%;
					    height:100%; 
					}
				}
		    }


		//////////////////////////////////////////////////////////////////////////
		//////////////////////////////////////////////////////////////////////////

	

		// ----
		// MEDIA QUERIES

		// -- Globals

			// All XS AND SM
			@media screen and (max-width: $scr-md){


			} // ALL XS and ALL SM

			// All XS
			@media screen and (max-width: $scr-sm){
				

			} // ALL XS

		// -- Specifics
		

		@media #{$mq-xxs}{

 			@include responsiveTest("XXS"); 

		}

		@media #{$mq-xs}{

 			@include responsiveTest("XS");

		}

		@media #{$mq-sm}{

 			@include responsiveTest("SM");

		}

		@media #{$mq-md}{

 			@include responsiveTest("MD");
			
		}

		@media #{$mq-lg}{

 			@include responsiveTest("LG");
			
		} 
		@media #{$mq-xlg}{

 			@include responsiveTest("XLG");

		}

		// END MEDIA QUERIES
		// ----
