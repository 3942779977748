// @font-face {
// 	font-family: 'icomoon';
// 	src:  url('../fonts/icomoon.eot?t5gtjm');
// 	src:  url('../fonts/icomoon.eot?t5gtjm#iefix') format('embedded-opentype'),
// 	url('../fonts/icomoon.woff2?t5gtjm') format('woff2'),
// 	url('../fonts/icomoon.ttf?t5gtjm') format('truetype'),
// 	url('../fonts/icomoon.woff?t5gtjm') format('woff'),
// 	url('../fonts/icomoon.svg?t5gtjm#icomoon') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }

// ---------------------------------------------
// COMIT - Icons
// --------------------------------------------- 

// Gulp fetched fonts that comit uses to your local fonts folder (/fonts/comit)
// We only need to redefined the font-face to use the new correct paths.

@font-face {
  font-family: 'aco';
  src:  url('../fonts/comit/aco.eot?e6r0ph');
  src:  url('../fonts/comit/aco.eot?e6r0ph#iefix') format('embedded-opentype'),
    url('../fonts/comit/aco.woff2?e6r0ph') format('woff2'),
    url('../fonts/comit/aco.ttf?e6r0ph') format('truetype'),
    url('../fonts/comit/aco.woff?e6r0ph') format('woff'),
    url('../fonts/comit/aco.svg?e6r0ph#aco') format('svg');
  font-weight: normal;
  font-style: normal;
}

// ---------------------------------------------
// CUSTOM - Icons
// --------------------------------------------- 

// [class*="icon-"]:not([class*="-icon"]):before {
// 	/* use !important to prevent issues with browser extensions that change fonts */
// 	font-family: 'icomoon' !important;
// 	speak: none;
// 	font-style: normal;
// 	font-weight: normal;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;

// 	/* Better Font Rendering =========== */
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }

// [class^="icon-arrow-left"]:before {
// 	content: "\e900";
// }
// [class^="icon-arrow-right"]:before {
// 	content: "\e901";
// }
// [class^="icon-close"]:before {
// 	content: "\e902";
// }
// [class^="icon-facebook"]:before {
// 	content: "\e903";
// }
// [class^="icon-instagram"]:before {
// 	content: "\e905";
// }
// [class^="icon-instagramlight"]:before {
// 	content: "\e904";
// }
// [class^="icon-play"]:before {
// 	content: "\e908";
// }
// [class^="icon-playlight"]:before {
// 	content: "\e907";
// }
// [class^="icon-twitter"]:before {
// 	content: "\e909";
// }

