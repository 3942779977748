// quand on fait des modifs, on doit aller gulp dans le theme


// stripe 
.StripeElement {
	box-sizing: border-box;
	padding: 10px 12px;	transition: box-shadow 150ms ease;
	border: 1px solid darken($lightColor, 10%);
	border-radius: 4px;
	background: $white;
	min-height: 46px;
	font-size: 14px;
	position: relative;
	box-shadow: none;
	padding-top: 14px;

	&.StripeElement--focus {
		border-color: $activeColor;
	}

	&.StripeElement--invalid {
		border-color: darken(red, 10%);
	}

	&.StripeElement--webkit-autofill {
		background-color: $white !important;
	}
}

// nuvei

/*********** base Nuvei Fields styles https://docs.nuvei.com/documentation/accept-payment/web-sdk/nuvei-fields/nuvei-fields-styling/ for other classes ************/
.SfcField {
	// margin-bottom: 20px;
    // position: relative;
    // min-height: 46px;
    // font-size: 14px;
	// border: 1px solid  $mediumColor;
	// border-radius: $borderRadius;
	// background: $white;
	@include spacing(11px);
	padding-left: 20px!important;
	padding-right: 20px!important;
}
/************* text boxes styles ****************/
/* box with focus when typing */
.SfcField.sfc-focus {
	border-color: $activeColor;
}
/* box when a validation error */
.sfc-invalid {
    border-color: $red;
}



// .ElementsApp, .ElementsApp .InputElement {
// 	font-family: $main!important;
// 	font-size: 14px!important;
// }

#card-errors {
	text-transform: uppercase;
	font-size: 0.8rem;
	letter-spacing: 0.02em;
	color: darken(red, 10%);
	// margin-top: -10px;
	margin-bottom: 20px;
	margin-top: 5px;
}


.CartInfos {
	& [class*="__stepThree"] {
		// display: none;
		&[class*="_list"] {
			&[class*="__content"] {
				
				&.-total {
					border-bottom: none;
					margin-bottom: 40px;
					text-align: right;
					li {
						vertical-align: top;

						.taxes-incluses {
							font-size: 85%;
							font-weight: 700;
						}

						p {
							line-height: 1.5;

							strong {
								font-size: 15px;
							}
						}

						> *:first-child {
							margin-top: 0;
						}

						> *:last-child {
							margin-bottom: 0;
						}

						&:first-child {
							width: 70%;
						}

						&:last-child {
							width: 30%;
							text-align: right;
						}

						@include media(XS) {
							&:first-child {
								width: 80%;
							}
	
							&:last-child {
								width: 20%;
								text-align: right;
							}
						}

					}
				}
			}

			&.has-fees {
				[class*="_item"] {
					&:first-child {
						width: 35%;
					}
					&:nth-child(2), &:nth-child(3) {
						width: 18%;
						text-align: center;
					}
					&:nth-child(4) {
						width: 14%;
						text-align: center;
					}
					&:nth-child(5) {
						width: 15%;
						text-align: right;
					}
				}

				&.-total {
					@include media(XS) {
						[class*="_item"] {
							&:first-child {
								width: 85%;
							}
							&:nth-child(2), &:nth-child(3) {
								width: 15%;
							}
						}
					}
				}
			}
		}
		// _fees
		&[class*="_fees"] {
			padding: 25px;
			h4 {
				margin: 0;
			}
			p {
				font-size: 12px;
				margin-top: 10px;
				margin-bottom: 0;
			}

			&[class*="_form"] {
				padding: 30px 0 0;
				
				.form-row {
					margin-bottom: 20px;
				}

				label {
					font-size: 13px;
					margin-bottom: 10px;
				}

				p {
					margin-bottom: 10px;
					font-size: 13px;
				}

				.form-element_checkbox {
					min-height: auto;

					label {
						margin-bottom: 0;
					}
				}
			}

			&[class*="_rowbadge"] {
				padding: 0;
				display: flex;
				flex-direction: column;
				
				> div {
					width: 100%;

					&.stripe-badge {
						margin-bottom: 20px;
					}
				}

				@media screen and (min-width: 480px) {
					justify-content: space-between;
					align-items: flex-end;
					flex-direction: row;

					> div {
						width: 48%;

						&:first-child {
							order: 2;
						}
					}
				}
			}
		}
		// _fees end
	}
}

// **---------------------------------------------------**
// -------------------------------------------------------
// .c-modalAge BEGIN

.c-ModalAge {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
    z-index: 7000;
    transition: all ease-in 0.5s;
	transition-delay: 0.25s;

	&__scroll {
		max-height: 100vh;
		overflow-y: scroll;
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

    // __container
    &__container {
        position: relative;
        width: 100%;
        max-width: 540px;
        z-index: 7100;
		max-height: 100vh;
		padding: 50px 0;
        transition: all ease-in 0.5s;
		transition-delay: 0s;
		opacity: 0;
    }

	&.is-shown {
		visibility: visible;
		pointer-events: all;
		opacity: 1;
		transition-delay: 0s;
	}

	&.is-shown & {
		&__container {
			transition-delay: 0.25s;
			opacity: 1;
			// transform: translateY(-100%);
        }
	}

	// &.is-hidden {
	// 	// transition: all ease-in 0s;
	// 	// opacity: 0;
	// 	visibility: hidden;
	// 	pointer-events: none;
	// 	// transform: translateY(-100%);
	// 	opacity: 0;
    // }

    // &.is-hidden & {
    //     // __container
    //     &__container {
    //         // transition: all ease-in 0s;
	// 		opacity: 0;
	// 		transform: translateY(-100%);
    //     }
    // }
}

.js-scrollLock {
	overflow: hidden;
	height: 100%;
}

// .c-modalAge END
// -------------------------------------------------------
// **---------------------------------------------------**


.table-responsive {

	@include media(MDmax) {
		.table-scroll {
			max-width:100%; 
			overflow:auto; 
			width:100%;
			position:relative; 
			overflow:hidden; /* Clips the shadow created with the pseudo-element in the next rule. Not necessary for the actual scrolling. */ 
			
			.tmpl_colored_table { 
				margin-bottom:2px; 
			}
	
			&:after {
				position:absolute; 
				top:0; 
				left:100%; 
				width:50px; 
				height:100%; 
				border-radius:10px 0 0 10px / 50% 0 0 50%; 
				box-shadow:-5px 0 10px rgba(0, 0, 0, 0.05); 
				content:''; 
			}
	
			.table-scroll-inner {
				overflow-x:scroll; 
	
				&:-webkit-scrollbar { 
					height:12px; 
				}
	
				&:-webkit-scrollbar-track { 
					box-shadow:0 0 2px rgba(0,0,0,0.15) inset; 
					background:#f0f0f0; 
				}
	
				&:-webkit-scrollbar-thumb { 
					border-radius:6px; 
					background:#ccc; 
				}

				table {
					min-width: calc(100% + 100px);

					tbody tr td.buy {
						min-width: 40px;
					}
				}
			}
		}
	}
}
