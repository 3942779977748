///////////////////////////////////////
///////////////////////////////////////
// STYLEGUIDE

*[data-href] {
    cursor: pointer;
}
* {
	::selection {
		background: $activeColor;
		color: $black;
	}
}

[class*="ic-"] {
	font-size: 0;
}

.container {
	width: 100%;
}

.alert {
	&.alert-success {
		margin-bottom: 0px;
	}
}

.Font-main {
	font-family: $main;
}

.Font-serif {
	font-family: $serif;
}

.Font-bold {
	font-weight: bold;
}

.ImgMax {
	max-width: 100%;
	height: auto;
}

html, body, h1, h2, h3, h4, h5, h6, li, p, input, textarea, select, small, sup {
	font-family: $main;
	font-variant-numeric: lining-nums;
	// WHITE TXT
	&.white {
		color: $white!important;
	}
	// ACTIVE COLOR TEXT
	&.activeColor {
		color: $activeColor!important;
	}
	// RED TEXT
	&.red {
		color: $red!important;
	}
	// PAYS
	&.pays {
		font-size: 30px;
		margin-bottom: 8px;
		font-family: $serif;
	}
}

strong, a {
	font-weight: 700;
	font-variant-numeric: lining-nums;
	font-weight: normal;
	// WHITE TXT
	&.white {
		color: $white!important;
	}
	// ACTIVE COLOR TEXT
	&.activeColor {
		color: $activeColor!important;
	}
	// RED TEXT
	&.red {
		color: $secondaryColor!important;
	}
	// PAYS
	&.pays {
		font-size: 30px;
		color: $mediumColor;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
}

.block {
	display: block;
}

.quote {
	position: relative;
	padding: 20px 40px;
	margin: 0;

	&:before {
		display: none;
	}

	svg {
		fill: $lighter-gray;
		position: absolute;

		&:first-child {
			top: 0;
			left: 0;
		}

		&:last-child {
			bottom: 0;
			right: 0;
		}
	}

	.text {
		z-index: 1;
		position: relative;
	}
}

hr {
	&.dotted {
		border: 0;
		height: 1px;
		background: url('../img/border-dot.png') bottom left repeat-x;
		// position: relative;
		// background: none;
		// width: 100%;
		// font-size: 10px;
		// &:before {
		// 	content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
		// 	position: absolute;
		// 	width: 100%;
		// 	height: 20px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// 	color: $activeColor;
		// 	overflow: hidden;
		// 	letter-spacing: 3px;
		// 	font-weight: 700;
		// 	margin-top: -5px;
		// }
	}

	&.large {
		margin: 40px 0;
	}
}

// HEADING STYLES

h1, h2, h3, h4, h5, h6 {
	font-family: $serif;
	font-weight: 500;
}

h1, .h1{
	@include rem(51); 
	margin-bottom:0.75em;
	margin-top:0;
}

h2, .h2 {
	display: inline-block;
	@include rem(30); 
	margin-bottom:0.5em;
	margin-top:0;
	// with-line
	&.with-lines {
		position: relative;
		&:before, &:after {
			content: '';
			position: absolute;
			width: 1px;
			height: 28px;
			background: $activeColor;
			top: 50%;
			transform: translateY(-50%);
			display: none;
		}
		&:before {
			left: -28px;
		}&:after {
			right: -28px;
		}
		// IF WHITE
		&.white {
			&:before, &:after {
				background: $white;
			}
		}
		@include media(xsmall) {
			&:before, &:after {
				content: '';
				display: block;
			}
		}		
	}
}

h3, .h3{
	@include rem(26);
	margin-bottom:1em;
	margin-top:0;
}
h4, .h4{
	@include rem(23.5);
	margin-bottom:1em;
	margin-top:0;
}
h5, .h5{
	@include rem(21);
	margin-bottom:1em;
	margin-top:0;
	font-weight:$fw-bold;
}
h6, .h6{
	@include rem(18);
	margin-bottom:1em;
	margin-top:0;
	font-weight:$fw-bold;
}

.uppercase {
	text-transform: uppercase;
}
.no-border {
	border: none!important;
}

.v-top {
	vertical-align: top!important;
}

.no-margin {
	margin: 0!important;
}

.text-left {
	text-align: left!important;
}

.text-right {
	text-align: right;
}

.title-with-ic {
	font-size: 0;
	.ic {
		display: inline-block;
		vertical-align: middle;
		margin-right: 25px;
	}
	.title {
		display: inline-block;
		vertical-align: middle;
	}
	.step-of {
		font-size: 14px;
		text-transform: none;
		letter-spacing: 1px;
		display: block;
		margin-top: 10px;
		font-weight: 500;

	}
}

// P STYLES

// LIST STYLES
ul {
	list-style: none;
	&.dotted {
		li {
			position: relative;
			overflow: hidden;
			padding: 0 0 7px 0;
			margin: 0 0 0 0;
			font-size: 14px;
			font-variant-numeric: lining-nums; 
			&:not(:last-child) {
				&:before {
					content: '';
					background: url('../img/border-dot.png');
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 1px;
				}
				// &:before {
				// 	color: $activeColor;
				// 	font-weight: 500;
				// 	content: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
				// 	position: absolute;
				// 	bottom: -2px;
				// }
			}
			&.price {
				color: $secondaryColor;
				font-weight: 700;
				font-variant-numeric: lining-nums;
			}
		}
	}
	&.unstyled {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			margin: 0;
			padding: 0;
		}
	}
}

// LINKS STYLE
a {
	transition: all ease .3s;
}

// button

// button
.btn {

	// bouton par défaut utilisé presque partout
	font-size: 16px;
	font-weight: $fw-bold;
	white-space: normal;
	padding: 12px 17px;
	transition: all 0.5s ease-in;
	border-radius: 4px;
	letter-spacing: $letterspacing;
	text-transform: uppercase;
	position: relative;
	background: $activeColor;
	border: 1px solid $activeColor;
	color: $white;

	svg {
		path {
			fill: $white;
			transition: all 0.5s ease-in;
		}
	}

	span {
		position: relative;
		z-index: 1;
		display: block;
		height: 100%;
	}

	&:hover, &:active, &:focus {
		background: darken($activeColor, 15%);
		border-color: darken($activeColor, 15%);
		cursor: pointer;
		box-shadow: none!important;
		color: $white;
	}

	&.active {
		background: none;
		color: $activeColor;
		box-shadow: none;

		&:hover, &:active, &:focus {
			background: $activeColor;
			border-color: $activeColor;
			color: $white;
		}
	}
	
	// minimal : bouton sans background 
	// (exemple : Accès au catalogue étendu, réinitialiser dans nos domaines, etc)
	&.btn-minimal {
		font-size: 14px;
		background: none;
		font-weight: 700;
		color: $activeColor;
		border: none;
		text-transform: none;

		&:hover, &:active, &:focus, &.active {
			color: darken($activeColor, 15%);
			box-shadow: none;
		}

		&.active {

			&:hover, &:active, &:focus {
				background: none;
				border: none;
				color: darken($activeColor, 15%);
			}
		}
	}

	// alt: 2e style de bouton utilisé en complément du Btn par défaut 
	// (exemple dans le compte pour différencier Mon compte et Historique de commande)
	&.btn-alt {
		background: none;
		color: $activeColor; 

		svg path {
			fill: $activeColor;
		}

		&:hover, &:active, &:focus {
			color: $white;
			background: darken($activeColor, 15%);

			svg path {
				fill: $white;
			}
		}

		&.active {
			background: $activeColor;
			color: $white;
		}
	}

	&.btn-small {
		padding: 7px 8px;
		font-size: 14px;
	}

	// if btn is first
	&.first {
		padding-left: 0;
	}
}

// .btn {
// 	font-size: 16px;
// 	font-weight: $fw-bold;
// 	white-space: normal;
// 	padding: 12px 17px;
// 	transition: all 0.5s ease-in;
// 	border-radius: 4px;
// 	letter-spacing: $letterspacing;
// 	text-transform: uppercase;
// 	// minimal
// 	&.btn-minimal {
// 		background: none;
// 		font-weight: 700;
// 		color: $activeColor;
// 		text-transform: none;
// 		&:focus {
// 			color: $activeColor;
// 		}
// 		&:hover, &.active {
// 			color: darken($activeColor, .2);
// 			box-shadow: none;
// 		}


// 	}
// 	&:active {
// 		box-shadow: none!important;
// 	}
// 	// btn one
// 	&.btn-one {
// 		background: none;
// 		border: 1px solid $activeColor;
// 		color: $activeColor;
// 		font-size: 16px;
// 		text-transform: uppercase;
// 		position: relative;
// 		span {
// 			position: relative;
// 			z-index: 1;
// 			display: block;
// 			height: 100%;
// 		}

// 		svg path {
// 			fill: $activeColor;
// 			transition: all 0.5s ease-in;
// 		}

// 		&:hover {
// 			color: $white!important;
// 			background: $activeColor;

// 			svg path {
// 				fill: $white;
// 			}
// 		}
// 		&:focus {
// 			color: $activeColor;
// 		}

// 		&.active {
// 			background: $activeColor!important;
// 			color: $white!important;
// 			box-shadow: none!important;
// 		}

// 		&.btn-fill {
// 			&.active {
// 				background: none!important;
// 				color: $activeColor!important;
// 			}
// 		}
// 	}
// 	&.btn-light-gray {
// 		background: $light-gray;
// 		&:hover {
// 			color: $black!important;
// 		}
// 	}
// 	&.btn-small {
// 		padding: 7px 8px;
// 		font-size: 14px;
// 	}

// 	&.btn-delete {
// 		opacity: .8;
// 	}
// 	&.btn-fill {
// 		background: $activeColor!important;
// 		color: $white!important;
// 		svg {
// 			path {
// 				fill: $white!important;
// 			}
// 		}

// 		&:hover {
// 			background: darken($activeColor, 15%) !important;
// 		}
// 	}


// 	// hover default
// 	&:hover {
// 		// color: $secondaryColor!important;
// 		cursor: pointer;
// 	}
// 	// if btn is first
// 	&.first {
// 		padding-left: 0;
// 	}
// }


// HR
hr {
	background: $activeColor;
}

.bg-white {
	background: $white;
}

.bg-light {
	background: $lightColor;
}

.bg-light-gray {
	background: $light-gray;
}

.bg-lighter-gray {
	background: $lighter-gray;
}
.bg-gray {
	background: $gray;
}

.bg-grayDark {
	background: $grayDark;
}

.bg-activeColor {
	background: $activeColor;
}

.bg-secondColor {
	background: $secondaryColor;
}

.bg-black {
	background: $black;
}

.mediumColor {
	color: $mediumColor;
}

.no-gutter {
	[class*="col"] {
		padding-left: 0;
		padding-right: 0;
	}
}

///////////////////////////////////////
///////////////////////////////////////
// SQUARE GRID LAYOUT
.square-grid {
	[class*="container"] {
		[class*="grid"] {
			font-size: 0;
			display: block;
			@include media(large) {
				display: table;
			}
			[class*="square"] {
				// display: inline-block;
				display: block;
				vertical-align: top;
				position: relative;
				// QUARTERS
				&[class*="-quarter"] {
					width: 100%;
					// padding-top: 50%;
				}
				// HALF
				&[class*="-half"] {
					width: 100%;
					// padding-top: 50%;
				}
				//////// SMALL
				@include media(small) {
					// QUARTERS
					&[class*="-quarter"] {
						width: 50%;
						// padding-top: 50%;
						display: inline-block;
					}
					// HALF
					&[class*="-half"] {
						width: 100%;
						// padding-top: 50%;
						display: block;
					}		
				}
				//////// SMALL END
				
				//////// LARGE
				@include media(large) {
					// QUARTERS
					&[class*="-quarter"] {
						width: 25%;
						display: table-cell;
						// padding-top: 30%;
					}
					// HALF
					&[class*="-half"] {
						width: 50%;
						// padding-top: 30%;
						display: table-cell;
					}
				}
				//////// LARGE END
			}
			// V MIDDLE
			[class$="-m"] {
				[class*="col-"] {
					vertical-align: middle;
				}
			}
			// V TOP
			[class$="-t"] {
				[class*="col-"] {
					vertical-align: top;
				}
			}
			// V BOTTOM
			[class$="-b"] {
				[class*="col-"] {
					vertical-align: bottom;
				}
			}
		} // *grid
	}
}

///////////////////////////////////////
///////////////////////////////////////
// FORM
[class*="form-element"] {
	min-height: 46px;
	font-size: 14px;
	// when disabled
	&.disable {
		opacity: .5;
		* {
			color: #1a1a1a;
		}
	}
	input {
		transition: box-shadow ease .3!important;
		&:focus {
			+ hr {
				background: $activeColor;
			}
		}

		// &.btn-one {
		// 	background: none;
		// 	border: 1px solid $activeColor;
		// }

		&.btn {
			&:empty {
				width: inherit!important;
				height: inherit!important;
				@extend .btn;

				&.btn-small {
					@extend .btn-small;
				}
			}
		}
	}
	textarea {
		min-height: 140px!important;
	}
	hr {
		background: rgba($mediumColor, .20);
		transition: all ease .3s;
	}
	&.boxed {
		background-color: $lightColor;
	}
	&.bordered {
		border: 1px solid $mediumColor;
		border-radius: 4px;
		background: $white;

		.SumoSelect {
			> .optWrapper, &.open > .CaptionCont {
				border-color: $mediumColor!important;
				box-shadow: none!important;
			}

			> .optWrapper.multiple > .options li.opt.selected span i, .select-all.selected > span i, .select-all.partial > span i {
				background-color: $activeColor!important;
			}

			&:focus > .CaptionCont, &:hover > .CaptionCont, &.open > .CaptionCont {
				box-shadow: none!important;
			}
		}

	
		&:focus-within {
			border-color: $activeColor;

			.SumoSelect {
				> .optWrapper, &.open > .CaptionCont {
					border-color: $activeColor!important;
				}
			}
		}

		// input
		input {
			@include spacing(11px);
			padding-left: 20px!important;
			padding-right: 20px!important;
			
			// text-transform: uppercase;
			::-webkit-input-placeholder { /* Safari, Chrome and Opera */
			  color: $textColor;
			}
			:-moz-placeholder { /* Firefox 18- */
			  color: $textColor;
			}
			::-moz-placeholder { /* Firefox 19+ */
			  color: $textColor;
			}
			:-ms-input-placeholder { /* IE 10+ */
			  color: $textColor;
			}
			::-ms-input-placeholder { /* Edge */
			  color: $textColor;
			}
			:placeholder-shown { /* Standard one last! */
			  color: $textColor;
			}
			&.input-loupe {
				padding-right: 45px!important;
			}

		}
		

		// select
		select {
			@include spacing(12px);
			padding-left: 20px!important;
			padding-right: 20px!important;
			// text-transform: uppercase;
			border: none;
			background: none;
			webkit-appearance: none;
    		-moz-appearance: none;
    		width: 100%;
		}
	}
	// with ic

	&.icon {
		padding-right: 35px;
	}

	[class*="ic-"] {
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		svg {
			
			path {
				fill: $mediumColor;
			}
		}
	}

	.ic-loupe {
		svg {
			width: 16px;
			height: 16px;
		}
	}

	input, .SumoSelect > .CaptionCont, .SumoSelect > .optWrapper > .options {
		font-size: 14px;
	}



	// XS MAX

	@include media(XSmax) {
		input, .SelectBox {
			font-size: 14px;
		}
		&.bordered input {
			padding-left: 12px !important;
			padding-right: 48px !important;
		}
		.SumoSelect > .CaptionCont {
			padding: 12px !important;
		}
	}
}

select::-ms-expand {
    display: none!important;
}

// LISTING

.price {
	border-top: 1px solid #909091;
	border-bottom: 1px solid #909091;
	font-variant-numeric: lining-nums;
	@include spacing(7px);
	p {
		color: $secondaryColor;
		margin: 0;
		padding: 0;
	}
	&.no-border {
		border: none!important;
	}
	// if empty
	&:empty {
		display: none;
	}
}

///////////////////////////////////////
///////////////////////////////////////
// TOOLBAR
.toolbar {
	.btn-minimal {
		&.winery_expand {
			padding-left: 0;
			padding-top: 4px;
			padding-bottom: 8px;
			&.active {
				span {
					&:before {
						content:'remove';
					}
				}
			}
		}
	}
	
	.list-inline {
		text-align: center;
		li {
			margin: 5px 14px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: $mediumColor;
			padding: 0;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			> label {
				&.btn-light-gray {
					color: $gray;
				}
				// is active
				&.active {
					background: none;
					border: 1px solid $activeColor;
					box-shadow: none!important;
				}
			}
			a {
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				color: $mediumColor;
				margin: 0;
				letter-spacing: $letterspacing;
				// txt
				.txt {
					display: inline-block;
					vertical-align: middle;
					margin-right: 12px;
				}
				// ic
				.ic {
					display: inline-block;
					vertical-align: middle;
					max-width: 20px;
					svg {
						max-width: 20px;
						width: 100%;
						max-height: 30px;
						transform: scale(1);
						transition: all ease .4s;
						display: block;
						path {
							fill: $activeColor;
							transition: all ease .4s;
						}
					}
				}
			}
			// hover
			&:hover {
				a {
					.ic {
						svg {
							transform: scale(1.15);
							path {
								fill: $activeColor;
							}
						}
					}
				}
			}

			// active
			&.active {
				a {
					color: $activeColor;
					.ic {
						svg {
							path {
								fill: $activeColor;
							}
						}
					}
				}
			}
			// pulled left/right
			&[class*="pull-"] {
				display: block;
				float: none!important;
				@include media(small) {
					display: inline-block;
					&[class*="-right"] {
						float: right!important;
					}
					&[class*="-left"] {
						float: left!important;
					}
				}
			}
		} // li

		// prev
		&.prev {
			margin-left: 0;
			li {
				margin-left: 0;
				a {
					.ic {
						position: relative;
						padding: 4px;
						margin-right: 5px;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
					.txt {
						padding-top: 1px;
					}
					&:hover {
						.txt {
							color: $activeColor;
						}
					}
				}
			}
		}
		
		// MEDIUM
		@include media(medium) {
			&.right {
				text-align: right!important;
				
			}
			&.left {
				text-align: left;
				
			}
		}
		// MEDIUM END
	}
	// tool bar share
	&.toolbar-share {
		.socials {
			// text-align: center;
			// width: 100%;
			li {
				margin: 0 ;
				svg {
					path {
						fill: $activeColor;
					}
				}
				&:first-child {
					// width: 100%;
					margin-bottom: 5px;
					margin-right: 5px;
				}
				&:not(:first-child) {
					width: auto;
					padding: 0 10px;
				}

				&:last-child {
					padding-right: 0;
				}
			}
			// medium
			// @include media(medium) {
				// li {
					// &:first-child {
						// width: auto;
						// margin-right: 15px;
					// }
				// }
			// }

		} // socials end
	} // toolbar share
} // tool bar end


.form-element {
	&.form-element-icon {
		input {
			padding-right: 45px !important;
		}
	}
}


///////////////////////////
// pagination
.PaginationWrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;

	@include media(medium) {
		justify-content: space-between;
		flex-wrap: nowrap;
	}
}

.pagination {
	display: block;
	margin: 0;
	
	ul {
		display: flex;
		// justify-content: center;
		padding: 0;
		margin: 0;
		flex-wrap: wrap;
		li {
			padding: 0;
			margin: 0;
			a {
				font-size: 1rem;
				font-variant-numeric: lining-nums;
				display: block;
				// background: $lightColor;
				color: $mediumColor;
				padding: 6px 10px;
				border: 1px solid darken($lightColor, 10%);
				margin-left: -1px;
				height: 100%;
				// active
				&:hover, &:active, &:focus, &.active {
					position: relative;
					background: $activeColor;
					color: $white;
					border-color: $activeColor;
				}
				&.active {
					font-weight: $fw-bold;
				}
			}

			&.prev, &.next {
				a {
					display: flex;
					align-items: center;
					padding: 5px;
				}
			}

			&:first-child{
				a {
					border-bottom-left-radius: 4px;
					border-top-left-radius: 4px;
				}
			}
			&:last-child {
				a {
					border-bottom-right-radius: 4px;
					border-top-right-radius: 4px;
				}
			}

			&.navigator {
				display: block;
				width: 15px;

				a {
					display: block;
				}
			}
		}

	}

	@include media(SMmax) {
		width: 100%;
		ul {
			justify-content: center;
		}
	}
}

.pagination-results {
	margin-left: auto;
	
	.form-element {
		width: 260px;
		margin: 0;
	}

	@include media(SMmax) {
		width: 100%;
		margin-top: 20px;

		.form-element {
			margin: 0 auto
		}
	}
}

///////////////////////////
// grid with border
li {
	position: relative;
	&.bordered {
		////// BORDER DOTS
		// default
		&:before, &:after {
			content: '';
			position: absolute;
			z-index: 0;
		}
		&:before {
			left: -30px;
			top: 1px;
			right: 1px;
			height: 1px;
			background-repeat: repeat-x;
			background: url('../img/border-dot.png');
		}
		&:after {
			display: none;
			top: 2px;
			bottom: -4px;
			right: 0;
			width: 1px;
			background-repeat: repeat-y;
			background: url('../img/border-dot-y.png');
		}
		&:first-child {
			&:before {
				display: none;
			}
		}


		&:last-child:not(:first-child):not(:nth-child(2)){
			&:before {
				left: 1px;
				top: 1px;
				right: 1px;
				height: 1px;
				width: 10000px;
				// left: 100%;
				background-repeat: repeat-x;
				background: url('../img/border-dot.png');
			}
		}

		&.two-columns {
			@include media(small) {
				// two first child, no border top
				&:first-child, &:nth-child(2) {
					&:before {
						display: none;
					}
				}
				// show border right only on odd li
				&:nth-child(odd) {
					&:after {
						display: block;
					}
				}
			}
		}

		//////////////////////////////////////////
		// for a grid of XS 1 / SM 2 / MD 3 / LG 4
		&.four-columns {
			// sm
			@include media(small) {
				// two first child, no border top
				&:first-child, &:nth-child(2) {
					&:before {
						display: none;
					}
				}
				// show border right only on odd li
				&:nth-child(odd) {
					&:after {
						display: block;
					}
				}
			}

			// md
			@include media(medium) {
				// third child, no border top
				&:nth-child(3) {
					&:before {
						display: none;
					}
				}

				&:nth-child(3n+3) {
					&:after {
						display: none;
					}
				}

				// we want border right only on item 1, 2, 4, 5, 7, 8 and so on
				&:nth-child(3n+2), &:nth-child(3n+4) {
					&:after {
						display: block;
					}
				}
			}
			// lg
			@include media(large) {
				// fourth child, no border top

				&:nth-child(3n+3) {
					&:after {
						display: block;
					}
				}

				&:nth-child(4) {
					&:before {
						display: none;
					}
				}
				// we now want border right only on item 1, 2, 3, 5, 6, 7 and so on
				// show all
				&:after {
					display: block;
				}
				// hide every 4 child
				&:nth-child(4n) {
					&:after {
						display: none;
					}
				}
			}
		} // four columns END

		//////////////////////////////////////////
		// for a grid of XS 1 / SM 2 / MD 3 / LG 3
		&.three-columns {
			// sm
			@include media(small) {
				// two first child, no border top
				&:first-child, &:nth-child(2) {
					&:before {
						display: none;
					}
				}
				// show border right only on odd li
				&:nth-child(odd) {
					&:after {
						display: block;
					}
				}
			}

			// md
			@include media(medium) {
				// third child, no border top
				&:nth-child(3) {
					&:before {
						display: none;
					}
				}
				// we want border right only on item 1, 2, 4, 5, 7, 8 and so on
				&:nth-child(3n+2), &:nth-child(3n+4) {
					&:after {
						display: block;
					}
				}
			}
			// lg
			@include media(large) {
				&:nth-child(3n+3) {
					&:after {
						display: none;
					}
				}
			}
		}
		
	} // wine borders
}


// EXPOSE ON FOCUS
.expose {
    position:relative;
}

#overlay {
    background:rgba(0,0,0,0.5);
    display:none;
    width:100%; height:100%;
    position:absolute; top:0; left:0; z-index:99998;
}

///////////////////////////
// Star/bottle rating
.Rating {
	// if is star
	.Rating_star {
		> .Rating_item {
			&:before {
				background-image: url('../images/star.png');
			}
		}
		> .Rating_item-full {
			&:before {
				background-image: url('../images/full-star.png');
			}
		}
		> .Rating_item-half {
			&:before {
				background-image: url('../images/half-star.png');
			}
		}
	}

	// if is wine
	.Rating_wine {
		> .Rating_item {
			&:before {
				background-image: url('../images/wine.png');
			}
		}
		> .Rating_item-full {
			&:before {
				background-image: url('../images/full-wine.png');
			}
		}
		> .Rating_item-half {
			&:before {
				background-image: url('../images/half-wine.png');
			}
		}
	}
	// item - li
	.Rating_item {
		width: 30px;
		height: 30px;
		position: relative;
		margin-right: 10px;
		&:before {
			content: '';
			width: 30px;
			height: 30px;
			display: block;
			background-size: contain;
		}
	}

	// -- smaller variant
	&.Rating-list, &.Rating-small {
		.Rating_item {
			width: 20px;
			height: 20px;
			margin-right: 4px;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
	}
}


///////////////////////////
// Sumo select

.SumoSelect {
	max-height: 46px!important;
	position: relative;
	top: -17px;

	
	
	&:focus, &:hover {
		> .CaptionCont {
			border: 0 solid transparent!important;
			box-shadow: none!important;
		}
	}

	> .CaptionCont {
		border: 0!important;
		background-color: transparent!important;
		position: absolute!important;
		padding: 13px 20px!important;
		display: block;
		width: 100%;
		height: 46px;
		> span {
			font-style: normal!important;
			color: $textColor!important;
		}
		> label {
			top: 50%!important;
			transform: translateY(-50%);
			bottom: auto!important;
			width: 40px!important;

			@extend .material-icons;
			@extend .Icon-center;

			&:before {
				content:'arrow_drop_down';
				font-size: 28px;
				color: $mediumColor;
			}

			i {
				display: none!important;
			}
		}
		> input {
			height: 46px!important;
			visibility: hidden;
			pointer-events: none;
		}
	}

	> .optWrapper {
		top: 40px!important;
		border: 1px solid $activeColor!important;
		border-top: 0!important;
		min-width: 100%!important;
		width: auto!important;
		left: -1px!important;
		right: -1px!important;
		border-radius: 0 0 3px 3px!important;
		font-size: 16px;
		> .options {
			> .opt {
				label {
					margin: 5px!important;
				}
			}
		}
	}

	// when sumo select is open
	&.open {
		> .CaptionCont {
			input {
				visibility: visible;
				pointer-events: auto;
			}
		}
	}
}

///////////////////////////
// Datepicker

#ui-datepicker-div {
	background: #f8f8f8;
	// box-shadow: 0px 0px 26px -5px rgba(0,0,0,0.30);
	// header
	.ui-datepicker-header {
		margin: 12px;
		// next
		.ui-datepicker-next {
			float: right;
		}
		.ui-datepicker-title {
			margin: 10px 0 0 0;
			text-transform: uppercase;
		}
	}
	// calendar
	.ui-datepicker-calendar {
		// head
		margin: 12px;
		thead {
			tr {
				th {
					padding: 0 4px;
					text-align: center;
				}
			}
		}
		// body
		tbody {
			tr {
				td {
					padding: 2px 4px;
					text-align: center;
				}
			}
		}
	}
}



::-webkit-input-placeholder { /* Safari, Chrome and Opera */
  color: $textColor!important;
}
:-moz-placeholder { /* Firefox 18- */
  color: $textColor!important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $textColor!important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $textColor!important;
}
::-ms-input-placeholder { /* Edge */
  color: $textColor!important;
}
:placeholder-shown { /* Standard one last! */
  color: $textColor!important;
}


// NEW COMIT STUFF / -- not by chuck


// ----------------------------------------
// Text Align
$ListTextAlign : (
	center,
	left,
	right
);

@each $breakpoint, $value, $condition in $ListBreakpoint {
	// print no MQ
	@each $align in $ListTextAlign {
		@if $breakpoint == null {
			.u-TextAlign-#{$align} {
				text-align: $align;
			}
		}
	}
	// print MQ
	@include media(#{$breakpoint}) {
		@each $align in $ListTextAlign {
			@if $breakpoint != null {
				.u-TextAlign-#{$align}-#{$breakpoint} {
					text-align: $align;
				}
			}
		}
	}
}


// ----------
// Margin / Padding utility
@each $breakpoint, $value in $ListBreakpoint {
	$property: margin;

	@if $breakpoint == null {
		.u-NoMargin{
			#{$property}: 0!important;
		}
	}
	@else {
		@include media(#{$breakpoint}) {
			/*#{$breakpoint}*//*#{$value}*/
			.u-NoMargin-#{$breakpoint} {
				#{$property}: 0!important;
			}
			.u-NoMargin-vertical-#{$breakpoint} {
				#{$property}-right: 0!important;
				#{$property}-left: 0!important;
			}
			.u-NoMargin-horizontal-#{$breakpoint} {
				#{$property}-top: 0!important;
				#{$property}-bottom: 0!important;
			}

			// Padding
			$property: padding;
			.u-NoPadding-#{$breakpoint} {
				#{$property}: 0!important;
			}
			.u-NoPadding-vertical-#{$breakpoint} {
				#{$property}-right: 0!important;
				#{$property}-left: 0!important;
			}
			.u-NoPadding-horizontal-#{$breakpoint} {
				#{$property}-top: 0!important;
				#{$property}-bottom: 0!important;
			}
		}
	}
	
}

// yes font-size: 0

.phpdebugbar {
	[class*="list"] {
		font-size: 13px;
	}
}

.debugbar {
	[class*="list"] {
		font-size: 13px;
	}
}




/// TABLE RESPONSIVE 

/* Class css à mettre sur un table pour le rendre responsive en mobile */
.scrollable { 
	max-width:100%; overflow:auto; width:100%;
	.tmpl_colored_table { 
		margin-bottom:2px; 
	}
}
.scrollable.has-scroll { 
	position:relative; 
	overflow:hidden; /* Clips the shadow created with the pseudo-element in the next rule. Not necessary for the actual scrolling. */ 
}
.scrollable.has-scroll:after { 
	position:absolute; 
	top:0; 
	left:100%; 
	width:50px; 
	height:100%; 
	border-radius:10px 0 0 10px / 50% 0 0 50%; 
	box-shadow:-5px 0 10px rgba(0, 0, 0, 0.05); 
	content:''; 
}
/* This is the element whose content will be scrolled if necessary */
.scrollable.has-scroll > div { 
	overflow-x:auto; 
}
.scrollable > div::-webkit-scrollbar { 
	height:12px; 
}
.scrollable > div::-webkit-scrollbar-track { 
	box-shadow:0 0 2px rgba(0,0,0,0.15) inset; 
	background:#f0f0f0; 
}
.scrollable > div::-webkit-scrollbar-thumb { 
	border-radius:6px; 
	background:#ccc; 
}




