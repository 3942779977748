	@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);
	
	// - Variables

		// 
	 	// Breakpoints

			//    -  320  -  480  -  768  -  1024  -  1200  -  1400 - +
			//   Mini    XXS      XS     SM       MD       LG        XLG

	 	   	$scr-xxs : 320px;
	 	   	$scr-xs : 480px;
	 	   	$scr-sm : 768px;
	 	   	$scr-md : 992px;
	 	   	$scr-lg : 1200px;
	 	   	$scr-xlg : 1600px;

	 	   		// MAXs

	 	   		$scr-xxs-max : ($scr-xs - 1);
	 	   		$scr-xs-max : ($scr-sm - 1);
	 	   		$scr-sm-max : ($scr-md - 1);
	 	   		$scr-md-max : ($scr-lg - 1);
	 	   		$scr-lg-max : ($scr-xlg - 1);

  				// MEDIA QUERIES BPs

  				$mq-xxs : "(min-width:#{$scr-xxs}) and (max-width:#{$scr-xxs-max})";
  				$mq-xs : "(min-width:#{$scr-xs}) and (max-width:#{$scr-xs-max})";
  				$mq-sm :  "(min-width:#{$scr-sm}) and (max-width:#{$scr-sm-max})";
  				$mq-md :  "(min-width:#{$scr-md}) and (max-width:#{$scr-md-max})";
  				$mq-lg :  "(min-width:#{$scr-lg}) and (max-width:#{$scr-lg-max})";
  				$mq-xlg :  "(min-width:#{$scr-xlg})";

				$mq-notdesk :  "(max-width:#{$scr-sm-max})";
				$mq-mob :  "(max-width:#{$scr-xs-max})" ;
  				$mq-desk :  "(min-width:#{$scr-md})";
 
 
		// Colors

			$black : #000000;
			$white : #ffffff;
	 		 
	 		$red : #d50834;
			$blue : #00a0e3;

			$gray : lighten($black, 50%);
			$gray_dark : lighten($black, 33%); 
			$gray_light : lighten($black, 80%); 
			$gray_lighter : lighten($black, 97%); 

			// Dominant color
			$activeColor : $blue; 
 

		// Typography
		 
			// Weights 
			$f-thin:100;
			$f-light:300;
			$f-normal:400;
			$f-medium:500;
			$f-bold:700;
			$f-ultrabold:900;
			

			$fontbase : 16;

			// px to rem
			@mixin rem($px){
				// rem = $px / 16
				$rem : $px / $fontbase;
				font-size:#{$rem}rem;
			}
		
		// 

		$sans : 'Roboto', Arial, Helvetica, sans-serif;  
		$f-main : $sans;
 
