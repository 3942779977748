// BODY
body {
	@include media(medium) {
		border-left: 20px solid $borderColor;
		border-right: 20px solid $borderColor;
		border-bottom: 20px solid $borderColor;
	}
}

html,body {
	color: $textColor;
}

///////////////////////////////////////
///////////////////////////////////////
// MENU
menu {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100%;
	z-index: 1000;
	visibility: hidden;
	// bg black
	&:before {
		content: '';
		background: url('../img/menu-bg.jpg') center center!important;
		background-size: cover!important;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: auto;
		background-color: $lightColor;
		transition: all ease-out .3s;
		opacity: 0;
	}
	[class*="line-"]{
		position: absolute;
		background: $borderColor;
		z-index: 50;
		// left
		&[class*="left"] {
			top: 0;
			left: 0;
			bottom: 100%;
			width: 12px;
		}
		// right
		&[class*="right"] {
			top: 100%;
			right: 0;
			bottom: 0;
			width: 12px;
		}
		// top
		&[class*="top"] {
			top: 0;
			right: 0;
			left: 100%;
			height: 12px;
		}
		// bottom
		&[class*="bottom"] {
			bottom: 0;
			right: 100%;
			left: 0;
			height: 12px;
		}
		@include media(small) {
			// left
			&[class*="left"] {
				width: 20px;
			}
			// right
			&[class*="right"] {
				width: 20px;
			}
			// top
			&[class*="top"] {
				height: 20px;
			}
			// bottom
			&[class*="bottom"] {
				height: 20px;
			}
		}
	}
	// WRAPPER
	.wrapper {
		position: absolute;
		z-index: 60;
		width: 80%;
		height: 80%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		// content
		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: 90%;
			width: 100%;
			// logo
			.logo {
				opacity: 0;
				transform: translateY(75px);
				margin-bottom: 15px;
				display: block;
				svg {
					max-width: 125px;
					fill: $white;
					path {
						fill: $white;
					}
				}

				span {
					display: inline-block;
					margin: 0 auto;
					max-width: 125px;
				}

				img {
					max-width: 100%;
				}

				@include media(small) {
					max-width: 100%;
					margin-bottom: 45px;
				}
			}
			// list
			ul {
				opacity: 0;
				transform: translatey(40px);
				padding: 0;
				li {
					margin: 0;
					padding: 0;
					a {
						text-transform: uppercase;
						font-size: calc(15px + 2vw);
						color: $white;
						padding: 0;
						&:hover {
							color: $white;
						}
					}
				}
				// @include media(small) {
				// 	li {
				// 		a {
				// 			font-size: 30px;
				// 		}
				// 	}
				// }
			}
			// close
			.closer {
				position: relative;
				z-index: 55;
				// border: 1px solid $secondaryColor;
				// border-radius: 50%;
				width: 55px;
				height: 55px;
				margin: 20px auto 0 auto;
				backface-visibility: hidden;
				-webkit-backface-visibility: hidden;
				opacity: 0;
				transform: translatey(40px);
				svg {
					path,rect {
						transition: all ease .3s;
						fill: $lightColor;
					}
				}
				&:hover {
					cursor: pointer;
					svg {
						path, rect {
							fill: $lightColor;
						}
					}
				}
				// span {
				// 	position: absolute;
				// 	width: 22px;
				// 	height: 22px;
				// 	display: block;
				// 	top: 50%;
				// 	left: 50%;
				// 	transform: translate(-50%, -50%);
				// 	&:before, &:after {
				// 		content: '';
				// 		width: 20px;
				// 		height: 1px;
				// 		background: $secondaryColor;
				// 		display: block;
				// 		-webkit-backface-visibility: hidden;
				// 		position: absolute;
				// 		top: 50%;

				// 	}
				// 	&:before {
				// 		transform: translateY(-50%) rotate(45deg);
				// 	}
				// 	&:after {
				// 		transform: translateY(-50%) rotate(-45deg);
				// 	}
				// }
				@include media(small) {
					margin: 65px auto 0 auto;
				}
			}
		}
	}

	// is-active
	&.is-active {
		visibility: visible;
		&:before {
			opacity: 1;
		}
		&:after {
			top: 0%;
			opacity: 1;
		}

		// wrapper
		.wrapper {
			.content {
				// logo
				.logo {
					opacity: 1;
					transform: translateY(0);
					transition: all ease-in-out .8s .1s;
				}
				// list
				ul {
					opacity: 1;
					transform: translatey(0);
					transition: all ease-in-out .8s .3s;
				}
				// closer
				.closer {
					opacity: 1;
					transform: translatey(0);
					transition: all ease-in-out .8s .8s;
				}
			}
		}

		// lines
		[class*="line-"] {
			// left
			&[class*="left"] {
				bottom: 0%;
				transition: all ease-in-out .5s 1s;
			}
			// right
			&[class*="right"] {
				top: 0%;
				transition: all ease-in-out .5s 1s;
			}
			// top
			&[class*="top"] {
				left: 0%;
				transition: all ease-in-out .5s 1.5s;
			}
			// bottom
			&[class*="bottom"] {
				right: 0%;
				transition: all ease-in-out .5s 1.5s;
			}


		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// NAV

.Navigation {
	background: $white;
	padding: 15px 0;
	border-bottom: 1px solid $lightColor;
	// GENERAL STYLE
	ul {
		li {
			color: $textColor;
			a, button {
				color: $textColor;
				font-size: 14px;
				font-weight: 700;
				> svg {
					path {
						fill: $black;
						transition: all ease .3s;
					}
				}

				&:hover, &:active, &:focus {
					color: $activeColor;

					> svg {
						path {
							fill: $activeColor;
						}
					}
				}
			}
		}
	}

	button {
		background: none;
		border: none;
	}
	// actions
	.actions {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		// hamburger
		.hamburger {
			position: absolute;
			top: 50%;
			left: 15px;
			transform: translate3d(0,-50%,0);
			height: 22px;
			width: 28px;
			display: block;
			z-index: 10;
			// lines
			.line {
				pointer-events: none;
				display: block;
				background: $activeColor;
				height: 2px;
				z-index: 1;
				// top line
				&:nth-child(1) {
					position: absolute;
					top: 4px;
					left: 0;
					right: 0;
					transform: translate3d(0,0,0);
				}
				// middle line
				&:nth-child(2) {
					position: absolute;
					top: 10px;
					left: 0;
					right: 0;
				}
				// bottom line
				&:nth-child(3) {
					position: absolute;
					bottom: 4px;
					left: 0;
					right: 0;
					transform: translate3d(0,0,0);
				}
			}
		} // hamburger
		.logo {
			position: static;
			top: auto;
			transform: translateY(0);
			margin-left: 40px;
			a {
				padding: 3px 0;
				display: inline-block;
				vertical-align: middle;
				span {
					display: block;
					min-width: 50px;
					img {
						width: 50px;
						max-width: 100%;
					}
				}
				svg {
					max-width: 100%;
					height: auto;
					display: block;
				}
			}
			@include media(medium) {
				margin-left: 0;
			}
			// sm +
			@include media(small) {
				a {
					span {
						img {
							width: 200px;
						}
					}
				}
			}
			// md END
		}
	} // actions

	// navs-wrapper
	.navs-wrapper {
		text-align: right;
		// primary
		.primary {
			display: none;
			@include media(medium) {
				display: block;
				margin-top: 20px;
				ul {
					li {
						display: inline-block;
						vertical-align: middle;
						margin: 0;
						padding: 0;
						a {
							padding: 0;
							display: inline-block;
							vertical-align: middle;
							font-size: 1rem;
							text-transform: uppercase;
							font-weight: $fw-bold;
							> svg {
								display: inline-block;
								vertical-align: middle;
								width: 20px;
								height: auto;
							}
						}
						&:not(:last-child) {
							margin-right: 42px;
						}
					}
				}
			}
		}
		// primary END

		// secondary
		.secondary {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			// SOCIALS
			.socials {
				display: none;
				margin-right: 30px;
				ul {
					margin: 0;
					display: flex;
					align-items: center;

					li {
						width: 20px;
						height: 20px;
						margin: 0;
						padding: 0;
						a {
							display: block;
							position: relative;
							padding: 0;
							height: 100%;
							width: 100%;
							// width: 18px;
							// height: 18px;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								max-width: 20px;
								height: auto;
								path {
									transition: all ease .3s;
									fill: $activeColor;
								}
							}
							&:hover {
								svg {
									path {
										fill: $textColor;
									}
								}
							}
						}
						&:not(:last-child) {
							margin-right: 5px;
						}
					}
				}
				@include media(small) {
					display: block;
				}
			} // socials
			// nav-second
			.nav-second-wrapper {
				position: relative;
			}
			.nav-second {
				> ul {
					margin: 0;
					display: flex;
					align-items: center;
					> li {
						margin: 0;
						padding: 0;
						> a, button {
							padding: 0;
							display: flex;
							align-items: center;
							color: $activeColor;
							font-size: 0.75rem;
							letter-spacing: $letterspacing;
							transition: all 0.3s ease;
							font-weight: $fw-normal;
							> svg {
								width: 20px;
								height: 20px;
								path {
									fill: $activeColor;
									transition: all ease .3s;
								}
							}
							&:hover {
								color: $textColor;
								> svg {
									path {
										fill : $textColor;
									}
								}
							}

						}
						&:not(:last-child) {
							margin-right: 16px;
						}
						&.contact-us, &.langue {
							a {
								-webkit-font-smoothing: antialiased;
							}
						}
						// login logout / cart
						&.logout, &.cart, &.account {
							a {
								svg {
									max-width: 15px;
									max-height: 16px;
								}
								span.cart_count {
									margin-left: 5px;
								}
							}
						}
						&.border-right {
							padding-right: 16px;
							border-right: 2px solid $activeColor;
							margin-right: 11px;
						}
						&.border-left {
							padding-left: 16px;
							border-left: 2px solid $activeColor;
							margin-left: 11px;
						}
						&.li-provinces {
							position: relative;

							> ul {
								position: absolute;
								right: 0;
								top: 40px;
								background: $white;
								width: 200px;
								z-index: 1;
								border-radius: 10px;
								padding: 10px 20px;
								text-align: left;
								opacity: 0;
								pointer-events: none;
								transition: opacity ease-out 0.5s, top ease-out 0.25s;

								&:after {
									content:'';
									position: absolute;
									bottom: calc(100% - 1px);
									right: 18px;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 0 8px 8px 8px;
									border-color: transparent transparent $white transparent;
								}

								li {
									margin: 0;
									display: block;
									a {
										font-size: 12px;
										font-family: $main;
										color: $mediumColor;
										padding: 5px 25px 5px 0px;
										margin-bottom: 5px;
										display: block;
										border-bottom: 1px solid #ebebeb;
										transition: all ease-out 0.3s;
										position: relative;

										&:after {
											content:'';
											background: url('../img/check-provinces.svg');
											width: 22px;
											height: 17px;
											position: absolute;
											right: 0;
											top: 50%;
											transform: translateY(-50%) scale(0.65);
											opacity: 0;
											transition: opacity ease-out .3s;
											transform-origin: center right;
										}
									}

									&:hover, &:active, &:focus {
										a {
											border-bottom: 1px solid $activeColor;
										}
									}

									&.active {
										a {
											&:after {
												opacity: 1;
											}
										}
									}
								}

								&.is-active {
									opacity: 1;
									pointer-events: auto;
									top: 30px;
									transition: opacity ease-out 0.25s, top ease-out 0.5s;
								}
							}
						}
						@include media(small) {
							&.logout, &.cart, &.account {
								a {
									svg {
										margin-right: 6px;
									}
								}
							}
						}

						@include media(XSmax) {
							a {
								span {
									display: none;
								}
							}
						}

					} // li
				} // ul
			} // nav-second

			@include media(XSmax) {
				flex-wrap: wrap;

				.nav-second, .socials {
					width: 100%;

					ul {
						justify-content: flex-end;
					}
				}

				.socials {
					margin-right: 0;
					margin-bottom: 10px;
				}
			}
		} // secondary
	}
} // nav

 // Search form header
 .SearchToggle {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	svg {
		fill: $activeColor;
		display: block;
		transition: all 0.3s ease;

		path {
			fill: $activeColor;
			transition: all 0.3s ease;
		}
	}

	.SearchOpen {
		svg {
			width: 16px;
			height: 16px;
		}
	}

	.SearchClose {
		display: none;
		svg {
			width: 16px;
			height: 17px;
		}
	}

	&.is-active {
		.SearchOpen {
			display: none;
		}
		.SearchClose {
			display: block;
		}
	}

	&:hover {
		svg {
			fill: $textColor;
			path {
				fill: $textColor;
			}
		}
	}
 }
 .SearchForm {
	opacity: 0;
	position: absolute;
	right: 0;
	width: 290px;
	top: calc(100% - 10px);
	z-index: 10;
	transition: all 0.5s ease-in;
	pointer-events: none;

	svg {
		fill: $activeColor;
		display: block;

		path {
			fill: $activeColor;
		}
	}

	.form-element {
		display: flex;
		align-items: center;
		background: $white;

		button {
			margin: 0 10px;
		}
	}

	&.is-active {
		opacity: 1;
		top: calc(100% + 10px);
		pointer-events: auto;
	}
 }

// trouver un vin
.nav-trouver {
	background: $white;
	padding: 3px 0;
	position: relative;
	// form
	.form-element {
		margin: 0;
		font-size: 0;
		.loupe {
			display: inline-block;
			vertical-align: middle;
			width: 47px;
		}
		input[type="text"] {
			display: inline-block;
			vertical-align: middle;
			width: calc( 100% - 47px);
			font-size: 20px;
			color: $mediumColor;
			text-transform: uppercase;
		}
	}
	// shadow
	.shadow {
		position: absolute;
		top: 100%;
		height: 20px;
		background: url('../img/shadow.png');
		background-position: center center;
		z-index: 1;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
}

///////////////////////////////////////
///////////////////////////////////////
// HEADER
// header {
// 	background: url('../img/header.jpg') center center;
// 	background-size: cover;
// 	position: relative;
// 	padding: 60px 0 97px 0;
// 	// logo
// 	.logo {
// 		margin: 0 auto;
// 		position: relative;
// 		display: block;
// 		background: $black;
// 		padding: 25px 45px;
// 		backface-visibility: hidden;
// 		-webkit-backface-visibility: hidden;
// 		-webkit-filter: blur(0px);
// 		filter: blur(0px);
// 		max-width: 200px;
// 		&:before {
// 			content: '';
// 			border: 1px solid $activeColor;
// 			position: absolute;
// 			top: 10px;
// 			left: 10px;
// 			bottom: 10px;
// 			right: 10px;
// 		}
// 		svg {
// 			max-width: 100%;
// 			width: 100%;
// 			height: auto;
// 			-webkit-filter: blur(0px);
// 			filter: blur(0px);
// 			-webkit-backface-visibility: hidden;
// 			backface-visibility: hidden;
// 		}
// 	}
// 	// small
// 	@include media(small) {
// 		padding: 80px 0 117px 0;
// 		// logo
// 		.logo {
// 			max-width: 250px;
// 		}
// 	}
// 	// small end

// 	// medium
// 	@include media(medium) {
// 		height: 910px;
// 		padding: 0;
// 		// logo
// 		.logo {
// 			position: absolute;
// 			top: 50%;
// 			left: 50%;
// 			transform: translate3d(-50.2%, -50%, 0);
// 			transform-origin: 0 0 0;
// 			max-width: 100%;
// 		}
// 	}
// 	// medium end

// 	@keyframes wheel {
// 		0% {
// 			transform: translateY(0px);
// 		}
// 		35% {
// 			transform: translateY(5px);
// 		}
// 		100% {
// 			transform: translateY(0);
// 		}
// 	}

// 	.mouse {
// 		position: absolute;
// 		bottom: 82px;
// 		left: 50%;
// 		transform: translateX(-50%) scale(1.3);
// 		svg {
// 			#wheel {
// 				animation: wheel 4s infinite;
// 			}
// 		}
// 	}
// 	// clip
// 	.clip {
// 		position: absolute;
// 		bottom: 0;
// 		width: 100%;
// 		height: 37px;
// 		// left
// 		.left {
// 			width: calc( 50% - 37px);
// 			height: 37px;
// 			background: $white;
// 			float: left;
// 			position: relative;
// 			&:before {
// 				content: '';
// 				position: absolute;
// 				bottom: 0;
// 				left: 100%;
// 				width: 0;
// 				height: 0;
// 				border-style: solid;
// 				border-width: 37px 0 0 37px;
// 				border-color: transparent transparent transparent $white;
// 			}
// 		}
// 		// right
// 		.right {
// 			width: calc( 50% - 37px);
// 			height: 37px;
// 			background: $white;
// 			float: right;
// 			position: relative;
// 			&:before {
// 				content: '';
// 				position: absolute;
// 				bottom: 0;
// 				right: 100%;
// 				width: 0;
// 				height: 0;
// 				border-style: solid;
// 				border-width: 0 0 37px 37px;
// 				border-color: transparent transparent $white transparent;
// 			}
// 		}
// 	}
// }

///////////////////////////////////////
///////////////////////////////////////
// LISTING
.editable {
	.title {
		// text-transform: uppercase;
		text-align: center;
	}
	// listing
	[class*="list-"] {

		// opacity effect
		// &:hover {
		// 	> li {
		// 		opacity: .5;
		// 	}
		// }
		> li {
			transition: all ease .3s;
			&:hover {
				opacity: 1;
			}
		}

		[class*="col-"] {
			// article
			article {
				a {
					* {
						color: $mediumColor;
					}
				}
				.col-img {
					width: 125px;
				}
				
				@include media(small) {
					.col-txt {
						width: calc(100% - 125px);
					}
				}


				.thumb-wrap {
					img {
						max-width: 100%!important;
						width: auto!important;
						height: auto;
						margin: 0 auto;
						display: block;
					}
				}
				h3 {
					font-size: 20px;
					color: $textColor;
					margin-top: 0;
					margin-bottom: 0;
				}
				h4 {
					font-family: $main!important;
					font-size: 16px;
					font-weight: 400;
					text-transform: uppercase;
					color: $textColor;
					margin-top: 0;
					margin-bottom: 10px;
					letter-spacing: $letterspacing;
				}

				p {
					font-size: 14px;
					font-style: italic;
					margin-bottom: 15px;
					// normal txt
					&.normal {
						font-style: normal;
						margin-top: 5px;
					}
				}
				.date {
					font-size: 12px;
					font-style: italic;
					color: $mediumColor;
					margin: 0;
				}
				.score {
					padding: 10px 0 8px 0;
					color: $secondaryColor;
					font-weight: 700;
					text-transform: uppercase;
					font-variant-numeric: lining-nums;
					li {
						color: $secondaryColor;
						font-weight: 700;
						padding: 0;
						margin: 0;
					}
				}
				.btn-minimal {
					padding-left: 0;
					padding-top: 0;
				}
			} // article
		}
	}
	// ACCESDER CATALOGUE
	.go-catalogue {
		margin: 0 auto;
		max-width: 460px;
		width: 100%;
		h4 {
			font-size: 24px;
			font-weight: $f-light;
			text-transform: uppercase;
		}
	}
}

// listing domaine
.domaines {
	.pays {
		position: relative;
		font-size: 16px;
		// font-family: $main!important;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -15px;
			right: 0;
			height: 1px;
			width: calc(100% - 30px);
			background-repeat: repeat-x;
			// background: url('../img/border-dot.png');
		}
		@include media(small) {
			font-size: 23px;
		}
		@include media(medium) {
			font-size: 27px;
		}
		@include media(large) {
			font-size: 30px;
		}
	}
	// &.editable {
		// listing
		[class*="list-"] {
			[class*="col-"] {
				// article
				article {
					.col-img {
						width: 125px;
						// display: none;
						display: block;
					}
					
					@include media(small) {
						.col-img {
							display: inline-block;
						}

						.col-txt {
							width: calc(100% - 125px);
						}
					}
				}
			}
		}
	// }
}

///////////////////////////////////////
///////////////////////////////////////
// ABOUT / HOME
.home-about {
	background: url('../img/about.jpg');
	background-position: center center;
	background-size: cover;
	.main-row {
		padding-bottom: 75px;
	}
	// LISTING
	[class*="list-"] {
		.cols {
			@include spacing(8px);
			.list-m {
				text-align: center;
				// SVG WRAP
				.thumb-wrap {
					margin-top: 15px;
					margin-bottom: 0;
				}
				p {
					color: $white;
					font-size: 16px;
					font-weight: 500;
					margin-bottom: 0;
				}
				@include media(small) {
					text-align: left;
					.thumb-wrap {
						margin-top: 0;
					}
				}
			}
		}
	}
}


///////////////////////////////////////
///////////////////////////////////////
// SQUARE GRID
.square-grid {
	[class*="container"] {
		[class*="grid"] {
			[class*="square"] {
				.content {
					height: 100%;
					width: 100%;
					padding: 25px 20px;
					@include media(medium) {
						padding: 30px 25px;
					}
				}
			}
		}
	}
	// CONTENT
	.content {
		h4 {
			font-weight: 700;
			color: $activeColor;
			text-transform: uppercase;
			margin: 0 0 3px 0;
			font-size: 16px;
		}
		.date {
			font-size: 12px;
			font-style: italic;
			color: $mediumColor;
			margin: 0;
		}
		p {
			color: $mediumColor;
			font-weight: 500;
			margin: 15px 0 0 0;
			font-size: 15px;
		}
		.btn {
			margin-top: 25px;
			font-size: 14px;
		}
		// FORM
		[class*="form-element"] {
			font-size: 14px;
			color: $mediumColor;
			text-transform: uppercase;
			margin-left: -15px;
			// checkbox
			&[class*="checkbox"] {
				margin-bottom: 0;
				label {
					font-size: 12px;
					text-transform: none;
					color: $mediumColor;
					letter-spacing: 1.1px;
				}
			}
			// submit
			&[class*="submit"] {
				label {
					input {
						padding: 0;
						margin: 0;
					}
				}
			}
		}
		// NEWSLETTER
		&.newsletter {
			.title {
				color: $mediumColor;
				font-size: 16px;
			}
		}
		// SOCIALS
		&.socials {
			ul.unstyled {
				.title-with-ic {
					@include spacing(8px);
					a {
						.ic {
							position: relative;
							padding: 22px;
							border: 2px solid $white!important;
							border-radius: 5px;
							transition: all ease .2;
							margin: 0 10px 0 0;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								max-width: 18px;
								height: auto;
								path {
									fill: $white;
									transition: all ease .2s;
								}
							}
						}
						.title{
							margin: 0;
							color: $white;
							font-size: 16px;
						}
						&:hover {
							.ic {
								border: 2px solid $activeColor;
								svg {
									path {
										fill: $white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// CATALOGUE


// DOMAINES

.domaines {
	article {
		.col-txt {
			.price {
				font-weight: 700;
				padding: 0;
				font-variant-numeric: lining-nums;
			}
			.titre-vin {
				font-size: 16px;
			}
			.more-infos {
				li {
					font-size: 12px;
					padding: 0;
					margin: 0;
				}
			}
		}
	}
	[class*="list-"] {
		overflow: hidden;

		&.ul-catalogue {
			display: flex;
			flex-wrap: wrap;
		}
	}
}



// CATALOGUE FULL
.catalogue-full {
	.toolbar {
		.toolbar-list {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			ul {
				margin: 0;
			}
		}
		@include media (SMmax) {
			text-align: center;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			.toolbar-list {
				order: 2;
				width: 100%;
				ul {
					li {
						margin: 5px 14px;
					}
				}
			}
			.wrapExtended {
				width: 100%;
				margin-bottom: 50px;
			}
		}
		@include media (medium) {
			.toolbar-list {
				ul {
					&.list-margin {
						margin-left: 100px;
					}
					&.list-print {
						position: absolute;
						right: 15px;
						top: 0;
					}
				}
			}

			.winery_expand  {
				position: absolute;
				left: 15px;
				top: 0;
			}
		}
		@include media (large) {
			.toolbar-list {
				ul {
					&.list-margin {
						margin-left: 0;
					}
				}
			}
		}
	}


	h3.pays {
		font-size: 25px;
		line-height: 30px;

		@include media(small) {
			font-size: 22px;
			line-height: 27px;
		}

		@include media(medium) {
			font-size: 30px;
			line-height: 35px;
		}
	}

	.title {
		color: $activeColor;
		text-transform: uppercase;
		font-size: 18px;
		line-height: 22px;
		@include media(small) {
			font-size: 20px;
			line-height: 23px;
		}

		@include media(medium) {
			font-size: 22px;
			line-height: 25px;
		}
	}

	h3.pays, .title {
		text-align: center;
	}

	h3.pays + .row.list-t {
		hr {
			display: none;
		}
	}

	@include media(medium) {
		h3.pays, .title {
			text-align: left;
		}
	}
	.rowInfos {
		hr {
			background: none;
		}
		.pays {
			margin-bottom: 8px;
		}
		.title {
			margin-bottom: 0;
			// font-size: 20px;
		}
	}
	.catalogue-block {
		[class*="list-"] {
			overflow: hidden;


			.ul-catalogue {
				display: flex;
				flex-wrap: wrap;
				padding: 30px 0;

				@include media(medium) {
					padding: 30px;
				}
			}

			ul {
				list-style: none;
				padding-left: 0;
				padding-right: 0;
				margin: 0;
				display: inline-block;
				width: 100%;

				// li
				li {
					text-align: center;
					padding: 0;
					position: relative;
					// wrapper
					.wrapper {
						position: relative;
						font-size: 0;
						display: block;
						// @include spacing(20px);

						h4 {
							font-size: 17px;
							color: $textColor;
							font-family: $main;
						}
						.kind {
							font-size: 16px;
							line-height: 24px;
							font-family: $serif;
							color: $textColor;
						}
						// main
						.main {
							display: inline-block;
							width: 125px;
							vertical-align: top;
							.img {
								margin-bottom: 20px;
								img {
									max-width: 100%;
									width: auto;
									display: block;
									margin: 0 auto;
									max-height: 212px!important;
								}
							}
							h4 {
								margin: 0 0 5px 0;
								display: none;
							}
							.kind {
								font-weight: 500;
								display: none;
							}

							.AddToCartIcon {
								display: none;
								position: absolute;
								bottom: 20px;
								left: 50%;
								transform: translateX(-50%);

								svg {
									fill: $activeColor;
									display: block;
									max-width: 25px;
									max-height: 25px;

									path {
										fill: $activeColor;
									}

								}
							}
						}
						//main end

						// more
						.more {
							display: inline-block;
							width: calc( 100% - 125px);
							vertical-align: top;
							text-align: left;
							padding-left: 0;
							z-index: 1;
							.content {
								h4 {
									margin: 0 0 5px 0;
								}
								.kind {
									margin-bottom: 10px;
								}
								.code {
									color: $mediumColor;
									font-size: 12px;
									@include spacing(10px);
									margin: 0;
									display: block;
								}
								.infos {
									margin: 0;
									@include spacing(5px);
									li {
										font-size: 12px;
										color: $mediumColor;
										text-align: left;
									}
								}
								.price {
									p {
										font-size: 18px;
										line-height: 20px;
									}
									.pricesave {
										color: $mediumColor;
										font-size: 12px;
									}
									.old {
										text-decoration: line-through;
									}
								}
								.size {
									margin: 0;
									font-size: 12px;
								}

								.backorder {
									text-transform: uppercase;
									font-size: 12px;
									color: $activeColor;
									margin-top: 10px;
									font-weight: $fw-bold;
								}

								.btn {
									margin-top: 10px;
								}

							}
						}
						//more end
					}
					// wrapper end
				}
				// li end
			}
			// MEDIUM / SHOW FULL HOVER EFFECT
			@include media(medium) {
				ul {
					// opcacity effect
					&:hover {
						> li {
							opacity: .5;
						}
					}
					> li {
						transition: all ease .2s;
						&:hover {
							opacity: 1;
						}
					}
					-webkit-backface-visibility: hidden;
					li {
						-webkit-backface-visibility: hidden;
						backface-visibility: hidden;
						.wrapper {
							-webkit-backface-visibility: hidden;
							backface-visibility: hidden;
							// main
							.main {
								padding: 30px;
								width: 100%;
								h4, .kind {
									display: block;
								}
								.AddToCartIcon {
									display: block;
								}
							}
							// main end

							// more
							.more {
								position: absolute;
								background: rgba($white, .98);
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								transform-origin: 50% 50%;
								transform: translate3d(0,0,0);
								opacity: 0;
								transition: all ease .3s;
								-webkit-backface-visibility: hidden;
								backface-visibility: hidden;
								display: block;
								width: 100%;
								text-align: center;
								padding-left: 30px;
								.content {
									position: absolute;
									top: 50%;
									left: 30px;
									right: 30px;
									transform: translate(0, -50%);
									.infos {
										li {
											text-align: center;
										}
									}
									.price {
										display: inline-block;
										padding-left: 30px;
										padding-right: 30px;
									}
								}
							}
							// more end
						}
						// hover li
						&:hover {
							.wrapper {
								.more {
									box-shadow: 0px 0px 26px -5px rgba(0,0,0,0.30);
									transform: scale(1.1);
									opacity: 1;
									.content {
										.infos {
											&:hover {
												li {
													opacity: 1!important;
												}
											}
										}
										.size {
											color: $activeColor;
										}
									}
								}
							}
						} // hover li end
					}
				} // li
			}


		} // list- end
	}

}

// CATALOGUE LIST
.catalogue-list {
	// .pays {
	// 	border-bottom: 4px solid $lightColor;
	// 	padding-bottom: 10px;
	// 	margin-bottom: 25px;
	// }
	.table {
		margin-bottom: 70px;
		border: none;
	}
	table {
		width: 100%;
		&:not(.extended) {
			tbody {
				tr {
					cursor: pointer;
				}
			}
		}
		input[type="checkbox"] {
			-webkit-appearance: checkbox !important;
		}
		// head
		thead {
			tr {
				// cat
				&.cat {
					border-bottom: 1px solid $mediumColor;
					td {
						color: $textColor;
						font-size: 12px;
						font-weight: 700;
						text-transform: uppercase;
						padding-top: 20px;
						padding-bottom: 6px;
						padding-left: 5px;
						padding-right: 5px;
						letter-spacing: $letterspacing;
					}
				}
				// cat end
			}
		}
		// head end

		// body
		tbody {
			tr {
				background: url('../img/border-dot.png');
				background-repeat: repeat-x;
				background-position: bottom;
				transition: all ease .3s;
				// background: $lightColor;

				// &:nth-child(2n) {
				// 	background: $white;
				// }

				td {
					padding-top: 10px;
					padding-bottom: 10px;
					color: $textColor;
					font-size: 12px;
					padding-left: 5px;
					padding-right: 5px;
					transition: all ease .3s;
					position: relative;
					text-transform: uppercase;
					letter-spacing: $letterspacing;

					@include media(medium) {
						font-size: 12px;
					}

					// td name
					&.name {
						min-width: 175px;
						width: 100%;
						// width: auto!important;
						padding-left: 30px;
						span {
							// display: inline-block;
							// vertical-align: middle;
							&.ic {
								// margin-right: 10px;
								position: absolute;
								left: 5px;
								top: 50%;
								transform: translateY(-50%);
								// @include media(XSmax) {
								// 	display: none;
								// }

								svg {
									display: block;
									path {
										fill: $activeColor;
									}
								}
							}
						}
						a {
							color: $textColor;
							font-size: 12px;

							@include media(medium) {
								font-size: 12px;
							}
						}
					}

					//td certifications
					&.certifications {
						min-width: 72px;
						padding-top: 8px;
						padding-bottom: 5px;

						svg {
							width: 18px;
							height: 18px;
						}
					}

					//td mille
					&.mille {
						min-width: 85px;
						max-width: 85px;
						width: 85px;
					}
					// td type
					&.type {
						min-width: 75px;
						max-width: 75px;
						width: 75px;
					}
					// td appro
					&.appro {
						min-width: 165px;
						max-width: 165px;
						width: 165px;
					}
					// td caisse
					&.caisse {
						min-width: 75px;
						max-width: 75px;
						width: 75px;
					}
					// td cspc
					&.cspc {
						min-width: 95px;
						max-width: 95px;
						width: 95px;
					}

					&.buy {
						padding-top: 5px;
						padding-bottom: 5px;
						button {
							background: none;
							border: none;
							position: relative;
							display: flex;
							align-items: center;

							svg {
								max-width: 19px;
								max-height: 19px;
								display: block;
								path {
									fill: $activeColor;
								}
							}
						}
					}
				}
				&:hover {
					@include media(medium) {
						z-index: 10;
						background-color: $lightColor;
						transition: all ease .3s;
						// td {
						// 	color: $white;
						// 	a {
						// 		color: $white;
						// 	}

						// 	svg {

						// 		path {
						// 			fill: $white;
						// 		}
						// 	}

						// 	button, span.ic {
						// 		svg {
						// 			path {
						// 				fill: $white;
						// 			}
						// 		}
						// 	}
						// }
					}
					// a {
					// 	color: $activeColor;
					// }
				}
			}
		}
		// body end
		td {
			font-size: 14px;
		}
	}
}


.catalogue-form {

	.desktopStickyForm {
		display: none;

		@include media(small) {
			display: block;
		}
	}

	.mobileStickyForm {
		display: flex;
		align-items: center;

		button {
			border: none;
			background: none;
			padding-top: 5px;
			svg {
				path {
					fill : $activeColor;
				}
			}
		}

		@include media(small) {
			display: none;
		}
	}

	.list-buttons {
		display: flex;

		li {
			&:not(:last-child) {
				margin-right: 15px;
			}

			&:last-child {
				margin-left: auto;
			}
		}

		.btn-search {
			display: none;
		}

		@include media('small') {
			.btn-search {
				display: block;
			}
		}

	}

	&:not(.catalogue-form-sticky) {
		.list-buttons {
			@include media(XSmax) {
				justify-content: center;
				flex-wrap: wrap;

				li {
					&:last-child {
						width: 100%;
						margin-top: 20px;
						text-align: center;
					}
				}
			}
		}
	}

	&.catalogue-form-sticky {
		position: fixed;
		top: -25px;
		left: 0;
		right: 0;
		background: $lightColor;
		z-index: 10;
		padding: 15px 0;
		opacity: 0;
		transition: all 0.5s ease-in;
		pointer-events: none;

		.flex {
			display: flex;
			align-items: center;
		}


		.form-element {
			flex-grow: 1;
			margin-bottom: 0;
		}

		.list-buttons {
			margin: 0;
			align-items: center;
			transition: all 0.5s ease-in;
		}

		&.is-sticky {
			top: 0;
			opacity: 1;
			transition: all 0.5s ease-in;
			pointer-events: all;
		}

		@include media(XSmax) {
			.flex {
				flex-wrap: wrap;
			}

			.btn {
				padding-left: 12px;
				padding-right: 12px;
				font-size: 16px;

				&.btn-small {
					padding-left: 6px;
					padding-right: 6px;
					font-size: 12px;
				}
			}

			.form-element {
				width: 100%;
				//margin-bottom: 15px;
			}

			.list-buttons {
				width: 100%;
				flex-grow: 1;
				li {
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
			}

			.btn-search {
				margin-left: auto;
			}
		}

		@include media(small) {
			.form-element {
				margin-right: 15px;
			}
		}
	}
}



///////////////////////////////////////
///////////////////////////////////////
// OFFRES

.listOffers {
	margin-bottom: 25px;
	h3 {
		margin: 45px 0px 20px 0px;
		@include media(XSmax) {
			text-align: center;
		}
	}
	li {
		background: url('../img/border-dot.png');
		background-repeat: repeat-x;
		background-position: bottom;
		transition: all ease .3s;
		// background: $lightColor;

		// &:nth-child(2n) {
		// 	background: $white;
		// }

		a {
			padding-top: 11px;
			padding-bottom: 10px;
			display: block;
			color: $textColor;
			font-size: 12px;
			text-transform: uppercase;
			padding-left: 30px;
			padding-right: 5px;
			transition: all ease .3s;
			position: relative;
			font-family: $main;
			position: relative;
			.name {
				font-weight: 700;
				color: $textColor;
			}
			.ic {
				position: absolute;
				top: 50%;
				transform: translateY(-40%);
				left: 5px;

				svg {
					path {
						fill: $activeColor;
					}
				}
			}
		}

		&:hover {
			background-color: $lightColor;
			transition: all ease .3s;
			// a {
			// 	color: $white;

			// 	.name {
			// 		color: $white;
			// 	}

			// 	.ic {
			// 		svg {
			// 			path {
			// 				fill: $white;
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// FICHE

.WineSingle_header {
	@media screen and (max-width: 480px) {
		.toolbar-share {
			.socials {
				&__text {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.toolbar-share {
			.prev {
				text-align: left;
			}
			.socials {
				text-align: right;
				li {
					&:first-child {
						width: auto;
					}
				}
			}
		}
		// pdf
		.PrintPdf {
			display: none;
		}
	}
	@include media(small) {
		// pdf
		.PrintPdf {
			.list-inline {
				text-align: right;
				// padding-right: 15px;
			}
		}
	}
	@media screen and (min-width: 769px) and (max-width: 991px) {
		.toolbar-share {
			.prev {
				text-align: left;
			}
			.socials {
				text-align: right;
				li {
					&:first-child {
						width: auto;
					}
				}
			}
		}
	}
}

.fiche {

	// header
	.header {
		//wine
		.wine {
			width: 90px;
			text-align: center;
			img {
				max-width: 100%;
				width: auto!important;
				height: auto;
			}
		}
		//txt
		.txt {
			width: calc(100% - 90px);
			.pays {
				margin-top: 0;
				margin-bottom: 10px;
				font-size: 26px;
				// font-family: $main;
			}
			.kind {
				// font-style: italic;
				font-size: 24px;
				line-height: 30px;
				margin-bottom: 0;
				// font-family: $serif;
			}
			.price {
				display: inline-block;
				margin: 10px 10px 10px 0;
				font-size: 24px;
				font-variant-numeric: lining-nums;
				font-weight: 700;
			}

			.Price-FlexParent {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 5px 0px;
			}

			.agent {
				margin-top: 25px;
				//display: flex;
				//align-items: center;

				dt {
					margin-right: 5px;
					font-size: 12px;
					text-transform: uppercase;
					line-height: 1;
				}

				dd {
					a {
						text-decoration: underline;
						font-weight: $fw-bold;

						&:hover {
							text-decoration: none;
							color: $activeColor;

						}
					}
				}

			}

			.deal {
				margin-top: 15px!important;
			}

			.inspire {
				font-size: 12px;
				margin: 0;

				span {
					font-weight: 700;
					color: $red;
				}
			}
			small {
				// margin-top: 10px;
				display: block;
			}
		}
		// add to
		@media screen and (max-width: 768px) {
			.col-addto {
				margin: 30px 0 0 90px;
			}
		}
		@include media(small) {
			.txt {
				width: calc(50% - 90px);
			}
		}
		@include media(medium) {
			.wine {
				display: none;
			}
			.txt {
				width: 50%;
			}
		}
	}
	// header end

	// aside
	aside {
		img {
			margin: 0 auto 50px auto;
			display: block;
			height:  auto;
		}
		.wine-bottle {
			img {
				max-width: 275px;
				max-height: 700px;
			}
		}
	}
	//aside end

	//infos
	.infos {
		///////////////
		// tabs nav
		.nav {
			border-bottom: none;
			font-size: 0;
			text-align: center;
			background: $lightColor;
			border-radius: 5px;
			display: flex;
			flex-flow: row nowrap;
			// li
			li {
				border: none;
				// padding: 0 15px;
				display: inline-block;
				vertical-align: middle;
				box-sizing: border-box;
				transition: all ease-out .6s;
				a {
					text-transform: uppercase;
					font-size: 15px;
					border: none;
					background: none;
					position: relative;
					display: inline-block;
					color: $textColor;
					width: 100%;
					@include spacing(15px);

				}

				&:before, &:after {
					content: '';
					position: absolute;
					bottom: 0;
					height: 2px;
					background: $activeColor;
					transition: all ease-out .2s;
					visibility: hidden;
				}
				&:before {
					right: 50%;
					left: 50%;
				}
				&:after {
					left: 50%;
					right: 50%;
				}

				// active
				&.active {
					border-radius: 5px;
					background: lighten($light-brown, 25%);
					// a {
					// 	color: $white;

					// }

					&:before {
						left: 0;
						visibility: visible;
					}
					&:after {
						right: 0;
						visibility: visible;
					}
				}
			}
			// li end

			// different numbers of li's
			&.nav-2 {
				li {
					width: 50%;
				}
			}
			&.nav-3 {
				li {
					width: 50%;
				}
			}

			&.nav-4 {
				li {
					width: 50%;
				}
			}

			// SMALL
			@include media(small) {
				&.nav-3 {
					li {
						width: 33.33333333%;
					}
				}
				&.nav-4 {
					li {
						width: 25%;
					}
				}
			}

		} // tab nav end
		////////////////

		// panel
		.tab-content {
			.tab-pane {
				//row table
				.row-table {
					margin: 0;
					@include spacing(5px);
					font-size: 0;
					.ic, .txt {
						display: inline-block;
						vertical-align: middle;
					}
					.ic {
						width: 15px;
						position: relative;
						height: 100%;
						margin-right: 2px;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: auto;
							max-width: 100%;
							height: auto;
							path {
								fill: $activeColor;
							}
						}
					}
					.txt {
						width: calc(100% - 22px);
						font-size: 12px;
						font-family: $main;
					}
					.content {
						font-size: 12px;
						a {
							font-size: 12px;
						}
					}
					// odd
					&:nth-child(odd) {
						background: $lightColor;
					}

					// small
					@include media(small) {
						.ic {
							width: 20px;
							margin-right: 10px;
						}
						.txt {
							width: calc(100% - 35px);
							font-size: 14px;
						}
						.content {
							font-size: 14px;
							a {
								font-size: 14px;
							}
						}
					}
				}
				// row table end

				// row about
				.about {
					h3 {
						margin-top: 0;
						margin-bottom: 15px;
					}
				}

				// row review
				.review {
					h3  {
						margin-top: 0;
					}
					article {
						position: relative;
						&:before {
							content: '';
							position: absolute;
							left: 15px;
							top: 1px;
							right: 15px;
							height: 1px;
							width: calc(100% - 30px);
							background-repeat: repeat-x;
							background: url('../border-dot.png');
						}
						.date {
							display: block;
							font-style: italic;
							font-size: 13px;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		// panel end
	}
	// ifnos end

}

///////////////////////////////////////
///////////////////////////////////////
// FOOTER
footer {
	padding-top: 0!important;

	@include media(medium) {
		&.bg-black {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: -1px;
				bottom: 0;
				width: 1px;
				background: $black;
			}
		}
	}
	.content {
		padding-bottom: 0!important;
		padding-top: 30px!important;
		position: static!important;

		// nav
		ul {
			margin: 0;
			padding: 0;
			li {
				@include spacing(5px);
				a {
					font-size: 12px;
					font-weight: 700;
					letter-spacing: 1.2px;
				}
			}
		}
		// logo
		.logo-wrapper {
			padding: 15px;
			margin-top: 25px;
		}

		// BOTTOM
		.bottom {
			text-align: center;
			padding-bottom: 20px;
			hr {
				background: $mediumColor;
			}
			p {
				font-size: 12px;
				color: $mediumColor;
				margin: 0;
				letter-spacing: 1.2px;
				a {
					font-size: 12px;
					color: $lightColor;
					letter-spacing: 1.2px;
				}
			}

		}

		// SMALL
		@include media(small) {
			padding-bottom: 0!important;
			padding-top: 60px!important;
			// logo
			.logo-wrapper {
				margin-top: 0;
				padding: 0;
				float: right;
			}
			// bottom
			.bottom {
				text-align: right;
			}
		}
		// MEDIUM
		@include media(medium) {
			// bottom
			.bottom {
				padding-bottom: 0;
			}
		}
	}

	// FOOTER PAGE
	&.page {
		background: $secondaryColor;
		// logo
		.logo-wrapper {
			// max-width: 280px!important;
			// width: 220px!important;
			margin: 0 auto;
			svg {
				max-width: 100%;
				height: auto;
				fill: $white;

				path {
					fill: $white;
				}
			}
			img {
				width: auto!important;
				max-width: 100%!important;
			}
		}
		// socials
		.socials {
			ul {
				text-align: center;
				@include media(small) {
					text-align: right;
				}
				li {
					// width: 50%;
					a, .ic {
						display: inline-block;
						vertical-align: middle;
						@include spacing(10px);
						.ic {
							position: relative;
							padding: 22px;
							// border: 2px solid $white;
							border-radius: 5px;
							transition: all ease .2s;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								path {
									fill: $thirdColor;
									transition: all ease .2s;
								}
							}
						}
						.title {
							color: #ebebeb;
							font-size: 16px;
							margin: 5px 0 0 0;
							@include media(medium) {
								margin: 0;
							}
						}
						&:hover {
							.ic {
								svg {
									path {
										fill: $white;
									}
								}
							}
						}
					}
				}
			}
		}
		// bottom
		.bottom {
			hr {
				background: $mediumColor;
			}
			.primary {
				// list
				ul {
					margin: 0;
					padding: 0;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					li {
						margin-right: 10px;
						padding: 7px 0;
						&:not(:first-child) {
							margin-left: 10px;
						}
						a {
							font-weight: 700;
							font-size: 12px;
							padding: 0;
							margin: 0;
							letter-spacing: $letterspacing;
							color: $thirdColor;
							&:hover, &:active, &:focus {
								color: $white;
							}
						}
						&:last-child {
							margin-right: 0;
						}
					}
				} // list
			}
			.kork {
				margin-top: 25px;

				a {
					text-decoration: underline;
				}
			}

			.acolyte {
				a {
					text-decoration: underline;
				}
			}

			p {
				font-size: 12px;
				margin: 0;
				color: $mediumColor;
				a {
					font-size: 12px;
					color: $lightColor;
				}
			}
		}

		// SMALL
		@include media(small) {
			.logo-wrapper {
				margin: 0;
			}
			// .socials {
			// 	ul {
			// 		li {
			// 			width: 100%;
			// 			max-width: 125px;
			// 		}
			// 	}
			// }
			// bottom
			.bottom {
				ul {
					text-align: center;
				}
				p {
					text-align: center;
				}
			}
		}
		// SMALL END

		// MEDIUM
		@include media(medium) {
			.socials {
				ul {
					text-align: right;
					// li {
					// 	max-width: 160px;
					// 	&:not(:last-child) {
					// 		margin-right: 25px;
					// 	}
					// 	a {
					// 		svg {
					// 			display: inline-block;
					// 			vertical-align: middle;
					// 		}
					// 		.title {
					// 			display: inline-block;
					// 			vertical-align: middle;
					// 			margin-left: 15px;
					// 		}
					// 	}
					// }
				}
			}
			// bottom
			.bottom {
				.primary {
					ul {
						justify-content: flex-start;
					}
				}
				.kork {
					margin-top: 0;
				}
				p {
					text-align: right;
				}
			}
		}
		// MEDIUM END
	} // footer .page end
} // footer end


///////////////////////////////////////
///////////////////////////////////////
// CART

.CartProgress {
	font-size: 0;
	margin: 0;
	position: relative;
	padding-top: 70px!important;
	@include media(small) {
		padding-top: 75px!important;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		height: 1px;
		background: rgba($activeColor, 0.5);
		top: 60px;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 50%;
		height: 3px;
		background: $activeColor;
		top: 59px;
		transition: all ease .4s;
	}
	& [class*="__item"] {
		font-size: 14px;
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		text-align: center;
		margin: 0;
		padding: 0;
		position: relative;
		// cart img
		&:before {
			display: none;
			content: url('../img/cart-reverse.svg');
			position: absolute;
			top: -45px;
			height: 18px;
			width: 25px;
			z-index: 1;
			left: 50%;
			transform: translateX(-50%);
		}
		&.--request {
			&:before {
				content: url('../img/cart-reverse_request.svg');
			}
		}
		&[class*="_left"] {
			text-align: left;
		}
		&[class*="_right"]{
			text-align: right;
		}
		// sm +
		@include media(small) {
			width: 25%;
		}
	}
	// step 1
	&.step-1 {
		&:after {
			right: calc(75%);
		}
		& [class*="__item"] {
			&:first-child {
				&:before {
					display: block;
					left: 25%;
					transform: translateX(-50%);
				}
			}
			&:nth-child(2), &:nth-child(3), &:nth-child(4) {
				display: none;
			}
		}
		// sm +
		@include media(small) {
			&:after {
				right: calc(100% - 30px);
			}
			& [class*="__item"] {
				&:first-child {
					&:before {
						display: block;
						left: 15px;
						transform: translateX(0);
					}
				}
				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					display: inline-block;
				}
			}
		}
	}
	// step 2
	&.step-2 {
		&:after {
			right: 50%;
		}
		& [class*="__item"] {
			&:nth-child(2) {
				&:before {
					display: block;
				}
			}
			&:nth-child(1), &:nth-child(3), &:nth-child(4) {
				display: none;
			}
		}
		// sm +
		@include media(small) {
			&:after {
				right: 62%;
			}
			& [class*="__item"] {
				&:nth-child(2) {
					&:before {
						display: block;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				&:nth-child(1), &:nth-child(3), &:nth-child(4) {
					display: inline-block;
				}
			}
		}
	}
	// step 3
	&.step-3 {
		&:after {
			right: 25%;
		}
		& [class*="__item"] {
			&:nth-child(3) {
				&:before {
					display: block;
				}
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(4) {
				display: none;
			}
		}
		// sm +
		@include media(small) {
			&:after {
				right: 37%;
			}
			& [class*="__item"] {
				&:nth-child(3) {
					&:before {
						display: block;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				&:nth-child(1), &:nth-child(2), &:nth-child(4) {
					display: inline-block;
				}
			}
		}
	}
	// step 4
	&.step-4 {
		&:after {
			right: 4%;
		}
		& [class*="__item"] {
			&:last-child {
				&:before {
					display: block;
					left: auto;
					right: 15px;
				}
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				display: none;
			}
		}
		// sm +
		@include media(small) {
			&:after {
				right: 12%;
			}
			& [class*="__item"] {
				&:nth-child(4) {
					&:before {
						display: block;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				&:nth-child(1), &:nth-child(2), &:nth-child(3) {
					display: inline-block;
				}
			}
		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// CART INFOS

.CartInfos {
	// __pageTitle
	& [class*="__pageTitle"] {
		.ic {
			margin-bottom: 10px;

			svg {
				path {
					fill: $activeColor;
				}
			}
		}
		.title {
			font-size: 18px;
		}
		@include media(small) {
			.ic {
				margin-bottom: 0;
			}
			.title {
				font-size: 24px;
			}
		}
	}
	// __pageTitle

	// __row

	.btn-delete {
		opacity: 1;
		&:hover {
			color: $activeColor;
			opacity: 0.8;
		}
		@media (min-width: $scr-xxs) and (max-width: $scr-xs-max) {
			text-align: left;
			padding-left: 0;
		}
	}

	@include media(medium) {
		--width-first: 12%;
		--width-second: 30%;
		--width-third: 12%;
		--width-fourth: 12%;
		--width-fifth: 13%;
		--width-sixth: 21%;	
		--width-seventh: 20%;

		.-seven {
			--width-first: 10%;
			--width-second: 25%;
			--width-third: 12%;
			--width-fourth: 11%;
			--width-fifth: 11%;
			--width-sixth: 11%;
			--width-seventh: 20%;
		}
	}

	@include media(large) {

		.-seven {
			--width-first: 12%;
			--width-second: 25%;
			--width-third: 11%;
			--width-seventh: 19%;
		}
	}

	& [class*="__row"] {
		font-size: 0;
		// _item
		&[class*="_item"] {
			font-size: 15px;
			display: inline-block;
			vertical-align: top;
		}
		// _item END

		// _header
		&[class*="_header"] {
			// background: lightColor;
			// border-radius: 5px;
			@include spacing(15px);
			margin-bottom: 10px;
			display: none;
			border-bottom: 1px solid $activeColor;
			& [class*="_item"] {
				font-weight: 700;
			}

			// md +
			@include media(medium) {
				display: block;
				
				& [class*="_item"] {
					padding-left: 7.5px;
					padding-right: 7.5px;
					text-align: center;

					// &.-right {
					// 	text-align: right;
					// }

					&:nth-child(1) {
						width: var(--width-first);
					}
					&:nth-child(2) {
						width: var(--width-second);
					}
					&:nth-child(3) {
						width: var(--width-third);
					}
					&:nth-child(4) {
						width: var(--width-fourth);
					}
					&:nth-child(5) {
						width: var(--width-fifth);
					}
					&:nth-child(6) {
						width: var(--width-sixth);
					}
					&:nth-child(7) {
						width: var(--width-seventh);
					}
				}

				
			}

			&.-seven {
				@include media(medium) {
					[class*="_item"] {
						&:nth-child(2) {
							margin-left: 0;
						}
						&:nth-child(3) {
							margin-left: 0;
						}
						&:nth-child(4) {
							margin-left: 0;
						}
						&:nth-child(5) {
							margin-left: 0;
						}
						&:nth-child(6) {
							margin-left: 0;
						}
						&:nth-child(7) {
							margin-left: 0;
						}
					}
				}
			}
			
		}
		// _header END

		// _content
		&[class*="_content"] {
			&:not([class*="__footer"]) {
				border-bottom: 2px solid $lightColor;
			}
			@include spacing(10px);
			& [class*="_item"] {
				font-size: 18px;
				font-variant-numeric: lining-nums;
				@include spacing(10px);
				padding-left: 7.5px;
				padding-right: 7.5px;
				width: 75%;
				margin-left: 25%;
				// &:last-child{
				// 	text-align: left;
				// }
				&.CartInfos_buttons {
					padding-right: 0;
					a {
						display: block;
						margin-bottom: 10px;
					}
				}
				img {
					max-width: 50px;
				}
				.heading {
					display: block;
					font-weight: 700;
					color: $activeColor;
					font-size: 13px;
				}
				.form-element {
					display: block;
					margin: 0 0 3px 0;
					max-width: 100px;
					text-align: center;
					input {
						text-align: center;
					}
					@include media(medium) {
						margin: 0 auto 3px auto;
					}
				}

				// input {
				// 	width: 80%;
				// }
				&:first-child {
					width: 25%;
					margin-left: 0;
					position: absolute;
				}
				//name
				&[class*="_name"] {
					h3 {
						font-size: 18px;
						margin-top: 0;
						margin-bottom: 3px;
						@include media(small) {
							font-size: 20px;
						}
					}
					p {
						font-weight: 500;
						font-size: 20px;
						margin-bottom: 10px;
						line-height: 1.3;
					}
					span {
						font-size: 13px;
						display: block;
						line-height: 1.3;
					}
				}
				// sm +
				@include media(small) {
					&:first-child {
						width: 25%;
						img {
							margin: 0 auto;
						}
					}
					&:nth-child(2) {
						width: 75%;
						margin-left: 25%;
					}
					&:nth-child(3) {
						width: 17%;
						margin-left: 25%;
					}
					&:nth-child(4) {
						width: 17%;
						margin-left: 0;
					}
					&:nth-child(5) {
						width: 18%;
						margin-left: 0;
					}
					&:nth-child(6) {
						width: 21%;
						margin-left: 0;
						// text-align: right;
					}
				}
				// md +
				@include media(medium) {
					text-align: center;

					// &.-right {
					// 	text-align: right;
					// }

					.heading {
						display: none;
					}
					&:first-child {
						width: var(--width-first);
						position: relative;
						margin-left: 0;
						img {
							margin: 0 auto;
						}
					}
					&:nth-child(2) {
						width: var(--width-second);
						margin-left: 0;
					}
					&:nth-child(3) {
						width: var(--width-third);
						margin-left: 0;
					}
					&:nth-child(4) {
						width: var(--width-fourth);
						margin-left: 0;
					}
					&:nth-child(5) {
						width: var(--width-fifth);
						margin-left: 0;
					}
					&:nth-child(6) {
						width: var(--width-sixth);
						margin-left: 0;
					}
					&:nth-child(7) {
						width: var(--width-seventh);
						margin-left: 0;
					}
				}
				// lg +
				@include media(large) {
					margin-left: 0!important;
					text-align: center;
					&:first-child {
						position: static;
					}
					&:nth-child(6) {
						margin-left: 0!important;
						// text-align: right;
					}
				}
			}

			&.-seven {
				// [class*="_item"] {
					
				// }

				@include media(small) {
					[class*="_item"] {
						&:first-child {
							width: 25%;
							
							img {
								margin: 0 auto;
							}
						}
						&:nth-child(2) {
							width: 75%;
							margin-left: 25%;
						}
						&:nth-child(3) {
							width: 18%;
							margin-left: 25%;
						}
						&:nth-child(4) {
							width: 18%;
							margin-left: 0;
						}
						&:nth-child(5) {
							width: 18%;
							margin-left: 0;
						}
						&:nth-child(6) {
							width: 19%;
							margin-left: 0;
							// text-align: right;
						}
						// &:nth-child(7) {
						// 	width: 75%;
						// 	margin-left: 25%;
						// }
					}
				}

				@include media(medium) {
					[class*="_item"] {
						&:nth-child(1) {
							width: var(--width-first);
						}

						&:nth-child(2) {
							width: var(--width-second);
							margin-left: 0;
						}
						&:nth-child(3) {
							width: var(--width-third);
							margin-left: 0;
						}
						&:nth-child(4) {
							width: var(--width-fourth);
							margin-left: 0;
						}
						&:nth-child(5) {
							width: var(--width-fifth);
							margin-left: 0;
						}
						&:nth-child(6) {
							width: var(--width-sixth);
							margin-left: 0;
						}
						&:nth-child(7) {
							width: var(--width-seventh);
							margin-left: 0;
						}
					}
				}
			}
		}
		// _content END

		// _footer
		&[class*="__footer"] {

			& [class*="_item"] {
				display: none;
				[class*="_checkbox"] {
					margin-bottom: 0;
				}
				&.total {
					display: inline-block;
				}
				// sm +
				@include media(small) {
					@include media(SMmax) {
						&.total {
							width: 75%!important;
							margin-left: 25%!important;	
						}
					}
				}
				// md +
				@include media(medium) {
					display: inline-block;

					&:nth-child(4) {
						width: calc(var(--width-fourth) - 60px);
					}

					&:nth-child(5) {
						width: calc(var(--width-fifth) + 120px);
					}

					&:nth-child(6) {
						width: calc(var(--width-sixth) - 60px);
					}
				}
			}

			&.-seven {
				[class*="_item"] {
					@include media(medium) {
						&:nth-child(4) {
							width: calc(var(--width-fourth));
						}

						&:nth-child(5) {
							width: calc(var(--width-fifth) - 60px);
						}
	
						&:nth-child(6) {
							width: calc(var(--width-sixth) + 120px);
						}
	
						&:nth-child(7) {
							width: calc(var(--width-seventh) - 60px);
						}
					}
				}
			}
		}
		// _footer END
	}
	// __row END

	// __navigator
	& [class*="__navigator"] {
		text-align: center;
		@include media(small) {
			text-align: right;
		}
		&[class*="_item"] {
			width: auto;
			vertical-align: middle;
			&:not(:last-child) {
				margin-right: 15px;
			}
			a, button{
				&:not(.btn) {
					background: rgba($activeColor, 0);
					color: $activeColor;
					span {
						&.ic {
							svg {
								path {
									transition: all 0.5s ease-in;
									fill: $activeColor;

								}
							}
						}
					}
				}
				span {
					display: inline-block;
					vertical-align: middle;
					&.ic {
						margin-right: 5px;
						margin-top: -2px;
						svg {
							width: 15px;
							path {
								transition: all 0.5s ease-in;
								fill: $white;

							}
						}
					}
				}
				// &:hover {
				// 	background: $activeColor;
				// 	span {
				// 		&.ic {
				// 			svg {
				// 				path {
				// 					fill: $white;
				// 				}
				// 			}
				// 		}
				// 	}
				// }
			}
			&[class*="_help"] {
				a, button {
					display: inline-block;
					span {
						font-size: 0;
						svg {
							path {
								fill: $activeColor;
							}
						}
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
			&[class*="_prev"] {
				text-align: left;
			}
			&[class*="_next"] {
				text-align: right;
				a, button {
					span {
						&.ic {
							// margin-top: 2px;
							margin-right: 0;
							margin-left: 5px;
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	// __navigator END

	label {
		&.error {
			position: absolute;
			top: 46px;
			font-size: 0.75rem;
		}
	}

	// __stepTwo
	& [class*="__step2"] {
		.title {
			font-size: 15px;
			text-align: left;
		}
		.shrink {
			@include media(medium) {
				padding: 0 30px;
				clear: both;
			}
		}
		label {
			margin-bottom: 3px;
		}
	}
	// __stepTwo END


	// __stepThree
	& [class*="__stepThree"] {
		.shrink {
			@include media(medium) {
				padding: 0 30px;
				clear: both;
			}
		}
		label {
			margin-bottom: 3px;
		}
		// list default
		&[class*="_list"] {
			& [class*="_item"] {
				font-size: 14px;
				display: inline-block;
				vertical-align: top;
				padding: 10px;
				&:first-child {
					width: 40%;
				}
				&:nth-child(2), &:nth-child(3) {
					width: 20%;
					text-align: center;
				}
				&:nth-child(4) {
					width: 20%;
					text-align: right;
				}
				// txt
				.name {
					margin: 0;
					font-size: 16px;
					span {
						font-size: 16px;
						display: block;
						text-transform: none;
						margin-top: 5px;
						font-family: $main;
					}
				}
				p {
					margin-top: 10px;
					margin-bottom: 0;
					display: block;
					line-height: 1.3;
					font-size: 13px;
				}
			}
		}
		// header
		&[class*="__header"] {
			background: $lightColor;
			margin-bottom: 15px;
			& [class*="_item"] {
				font-weight: 700;
				font-size: 10px!important;
				@include media(small) {
					font-size: 12px!important;
				}
			}
		}
		// content
		&[class*="__content"] {
			padding: 10px 0 6px 0;
			border-bottom: 2px solid rgba($activeColor, .35);
			& [class*="_item"] {
				vertical-align: middle;
			}
			// &.-total voir global.scss de korkbase-template
		}

		// _infoSummary
		&[class*="_infoSummary"] {
			p {
				small {
					font-weight: 700;
				}
			}
		}
		// _infoSummary end

		// _fees - voir global.scss de korkbase-template
		strong {
			font-weight: 700;
		}
		.StripeElement {
			border: 1px solid $mediumColor;
		}
	}
	// __stepThree

	// __stepFour
	& [class*="__stepFour"] {
		& [class*="_confirm"] {
			svg {
				path {
					fill: $activeColor;
				}
			}
		}
	}
	// __stepFour END
}

///////////////////////////////////////
///////////////////////////////////////
// MY ACCOUNT
.MyAccount {
	.title {
		text-align: left;
		font-size: 18px;
		letter-spacing: $letterspacing;
		margin: 25px 0 8px 0;
	}
	h2 {
		&.title {
			font-size: 20px;
		}
	}
	.form-element {
		&.bordered {
			border-color: $activeColor;
		}
	}

	// __orderTable
	& [class*="__orderTable"] {
		table {
			margin-bottom: 0;
			&:not(:last-child) {
				margin-bottom: 35px;
			}
			//thead
			thead {
				display: none;
				tr {
					& [class*="__item"] {
						font-weight: 700;
					}
				}
				// md +
				@include media(medium) {
					display: table-header-group;
				}
				// md + END
			}
			//thead END
			// tbody
			tbody {
				tr {
					& [class*="__item"] {
						position: relative;
						display: block;
						&:before {
							content: attr(data-th) ": ";
							font-weight: 700;
							display: inline-block;
							vertical-align: middle;
							width: 150px;
						}
						// __actions
						&[class*="__actions"] {
							position: relative;
							padding-top: 40px;
							a {
								display: block;
								width: auto;
								margin-bottom: 5px;
							}
							&:before {
								position: absolute;
								top: 8px;
							}
							// 480px +
							@media screen and (min-width: 480px) {
								a {
									display: inline-block;
									margin-bottom: 0;
								}
							}
							// sm +
							@include media(small) {
								position: static;
								padding-top: 8px;
								&:before {
									position: static;
									top: auto;
								}
							}
						}


						// md+
						@include media(medium) {
							position: static;
							display: table-cell;
							&:before {
								content: '';
								display: none;
							}
							// last TD
							&:last-child {
								text-align: right;
							}
						}
						// md + END
					}
				}
			}
			// tbody
		}
	}
	// __orderTable END

	// MyAccount-addressWrap
	.MyAccount-addressWrap {
		.js-address-list {
			.js-address {

				.addressWrap_actions {
					padding-top: 50px;
				}

				&:not(:first-child) {
					position: relative;
					margin-top: 30px;
					&:after {
						content: '';
						position: absolute;
						top: -15px;
						left: 0;
						right: 0;
						background: darken($light-gray, 12%);
						height: 1px;
					}
				}
			}
		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// AddToCart
.AddToCart {
	//bo
	.AddTocart_backorder {
		text-transform: uppercase;
		font-weight: $fw-bold;
		color: $activeColor;
	}
	// quantity
	.AddTocart_quantite {


		color: $activeColor;
		font-weight: 700;
		font-size: 20px;
		margin-bottom: 0;
		span {
			color: $mediumColor;
			font-weight: normal;
			font-size: 12px;
		}
		@include media(small) {

		}
	}
	// input
	.AddToCart_input {
		max-width: 161px;
		// display: inline-block;
		// background: $lightColor;
		display: flex;
		align-items: center;
		margin: 13px 0 20px auto;

		// + -
		.increment {
			display: flex;
			align-items: center;


			color: $white;
			background: $lightColor;
			width: 36px;
			height: 46px;
			position: relative;
			svg {
				max-width: 15px;
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				path, rect, circle, polygon {
					fill: lighten($activeColor, 12%);
					transition: all ease .3s;
				}
			}
			&:hover {
				svg {
					path, rect, circle, polygon {
						fill: $activeColor;
					}
				}
			}
		}
		// form element
		.form-element {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
			max-width: 90px;
			width: 100%;
			height: 46px;
			border: 1px solid $lightColor;
			border-left: none;
			border-right: none;
			input {
				text-align: center;
				&:focus {
					box-shadow: none;
				}
			}
		}

		@media screen and (max-width: 768px) {
			margin: 13px 0 20px 0;
		}
	}
	// btn
	.btn {
		.text {
			margin-right: 30px;
		}
		.ic {
			position: absolute;
			top: 50%;
			right: 12px;
			transform: translateY(-50%);
			max-height: 25px;
			display: flex;
			align-items: center;
			svg {
				max-width: 25px;
				max-height: 25px;
			}
		}

		&.btn-small {
			.ic {
				svg {
					max-width: 20px;
					max-height: 20px;
				}
			}
		}
	}
	// small
	.txt-preorder {
		display: block;
		font-size: 60%;
		margin-top: 10px;
	}
}

///////////////////////////////////////
///////////////////////////////////////
// REVIEW SINGLE
.ReviewSingle {
	padding-right: 45px;
	padding-left: 45px;
	&:before {
		border: none;
		display: none;
	}
	// __header
	& [class*="__header"] {
		img {
			width: auto!important;
			max-width: 100%!important;
			height: auto;
		}
		p {
			font-weight: 700;
			font-size:19px;
			font-variant-numeric: lining-nums;
		}
		// sm +
		@include media(small) {
			p {
				font-size: 26px;
			}
		}
	}
	// __header END

	// __ content
	& [class*="__content"] {
		position: relative;
		text-align: justify;
		&:before, &:after {
			position: absolute;
			z-index: -1;
			opacity: .4;
		}
		&:before {
			content: url('../img/quote-open.svg');
			top: 0;
			left: -45px;
		}
		&:after {
			content: url('../img/quote-close.svg');
			bottom: 35px;
			right: -45px;
		}
	}
	// __content END
}


///////////////////////////////////////
///////////////////////////////////////
// WEEKLY OFFERS
.WeeklyOffers {
	// __content
	& [class*="__content"] {
		tr {
			.name {
				position: relative;
				padding-left: 30px;
				.ic {
					position: absolute;
					left: 0;
				}
			}
		}
	}
	// __ content END
}

///////////////////////////////////////
///////////////////////////////////////
// ACCOUNT LOGIN
.AccountLogin {
	& [class*="__login"] {
		button {
			margin-top: 10px;
		}
		.btn-minimal {
			margin-top: 10px;
			padding-left: 0;
		}
		@media screen and (min-width: 480px) {
			.btn-minimal {
				padding-left: 20px;
			}
		}
	}
}

///////////////////////////////////////
///////////////////////////////////////
// ADMIN LOGIN BOX
#login, #lostpassword {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, .7);
	// modal
	.modal-dialog {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.modal-content {
			background: none;
			box-shadow: none;
			border: none;
			// header
			.modal-header {
				border: none;
				padding-bottom: 45px;
				.close {
					color: $white;
					opacity: 1;
				}
			}
			// body
			.modal-body {
				#loginLabel {
					font-family: $f-main!important;
					color: $white;
					font-size: 20px;
				}

				form {

					[class*="btn-"] {
						border: 2px solid $activeColor;
						border-radius: 5px;
						padding: 12px 17px;
						font-family: $main!important;
						text-transform: uppercase!important;
						font-weight: $f-bold!important;
						background: $activeColor;
						color: $white;
					}

					label {
						color: $white;
					}
				}
				.btn-lostpassword, .btn-login {
					display: block;
					margin-top: 10px;
					padding: 12px 17px 12px 0;
					font-family: $main!important;
					color: $white;
				}
			}
		}
	}
	// modal END
}

#lostpassword {

	// modal
	.modal-dialog {

		.modal-content {

			// body
			.modal-body {

				form {

					.form-group {
						input {
							display: block;
							width: 100%;
							height: 34px;
							padding: 6px 12px;
							font-size: 14px;
							line-height: 1.428571429;
							color: #555555;
							background-color: #fff;
							background-image: none;
							border: 1px solid #ccc;
							border-radius: 4px;
						}
					}

				}
			}
		}
	}
	// modal END
}

// ////////////////////////
// MODALS

// add to cart modals

#modalAcheter {
	.modal-dialog {
		.modal-header {
			h3 {
				margin: 0;
				font-size: 25px;
			}
		}
		// body
		.modal-body {
			.titreBrand {
				font-size: 26px;
				margin-bottom: 5px;
			}
			.kind {
				font-size: 24px;
			}
		}
	}
}

// modal backdrop
.modal-backdrop.in {
	opacity: .8;
}



#modalAddToCart {
	.AddToCart {
		&_input {
			margin: 15px 0 20px 0;
		}
	}
	.modal-header {
		h3 {
			margin: 0;
			font-size: 25px;
		}
	}
	.modal-body {
		.titreVin {
			font-family: $main;
		}
	}
}




// WINERY

.winery-infos {
	@include media(medium){
		.btn-minimal {
			padding-left: 0;
		}
	}
}




// Choose province

.choose-province {
 	.container {
 		display: flex;
 		justify-content: center;
 	}

 	.choose-province-center {
 		min-height: calc(100vh - 314px - 81px);
 		display:flex;
 		align-items: center;

 		@include media(small) {
 			min-height: calc(100vh - 301px - 75px);
 		}

 		@include media(medium) {
 			min-height: calc(100vh - 275px - 75px - 20px);
 		}
 	}



 	h1 {
 		@include rem(35);
 		margin: 0;
 	}

 	hr {
 		width: 85px;
 		display: inline-block;
 		margin: 43px 0px 27px;
 	}

 	ul {
 		margin: 0;
 		li {

 			a {
 				@include rem(22);
 				font-family: $main;
 				color: $mediumColor;
 				position: relative;
 				padding: 0px 37px;
 				transition: color ease-out .3s;

 				&:after {
 					content:'';
 					background: url('../img/check-provinces.svg');
 					width: 22px;
 					height: 17px;
 					position: absolute;
 					right: 0;
 					top: 50%;
 					transform: translateY(-50%);
 					opacity: 0;
 					transition: opacity ease-out .3s;
 				}

 				&:hover, &:active, &:focus {
 					color: $black;
 					&:after {
 						opacity: 1;
 					}
 				}
 			}

 			&:not(:last-child) {
 				padding-bottom: 10px;
 			}


 		}
 	}
 }


 // liste certifications dans les fiches et à la fin du catalogue list
 ul.certifications {
	display: flex;
	flex-wrap: wrap;
	margin: 15px 0 0;

	li {
		display: flex;
		align-items: center;
		line-height: 2.5;

		svg {
			margin-right: 5px;
			width: 25px;
			height: 25px;
		}

		small {
			font-size: 13px;
		}

		&:not(:last-child) {
			margin-right: 15px;
		}
	}
}


// abonnement à l'infolettre dans le footer
 .Abonnement {

	 &_text {

		 color: $white;

		 h3 {
			 margin-bottom: 15px;
		 }
	 }

	 &_form {

		.btn {
			border: 1px solid $white;
			color: $white;
			width: inherit!important;
			height: inherit!important;
			padding: 12px 50px!important;
		}
	}

	@include media(small) {
		display: flex;
		 align-items: flex-end;

		 &_text {
			width: 40%;

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		 }

		 &_form {
			width: calc(60% - 60px);
			margin-left: auto;
		 }
	}

	@include media(large) {
		&_form {
			&_inner {
				display: flex;

				.form-element {
					flex-grow: 1;
					margin-right: 30px;
					margin-bottom: 0;
				}
			}
		}
	}
 }


.admin-fixed-button a {
	color: $lightColor;
}

.mailVerificationBox {
	padding-top: calc(40px + 0.8em);

	&__wrapper {
		display: flex;
		justify-content: center;

		&--content {
			display: inline-block;
			padding: 15px;
			border: 1px solid $black; // changer le $black pour $white sur le theme oeno

			p {
				margin-bottom: 0;
				color: $textColor;
				text-align: center;
			}
		}
	}
}


// Nav Sticky
.stickyNav {
	background: $white;
	margin-top: -15px;
	margin-bottom: 15px;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__logo {
		padding: 15px 0;

		a {
			span {
				display: block;
				min-width: 35px;
				img {
					width: 35px;
					max-width: 100%;
				}
			}
			svg {
				width: 60px;
				height: auto;
				display: block;
				fill: $black;
			}
		}

		@include media(small) {
			a {
				span {
					min-width: 45px;

					img {
						width: 45px;
					}
				}
			}
		}
	}

	.sticky {
		> ul {
			display: flex;
			align-items: center;

			> li {
				color: $activeColor;


				&:not(:last-child) {
					margin-right: 20px;
				}


				a, button {
					display: flex;
					align-items: center;
					color: $activeColor;
					font-size: 14px;
					font-weight: 400;
					letter-spacing: 1px;
					padding: 0;

					> svg {
						width: 18px;
						margin-right: 10px;

						path {
							fill: $activeColor;
							transition: all ease .3s;
						}
					}

					&:hover, &:active, &:focus {
						color: $black;
						> svg {
							path {
								fill: $black;
							}
						}
					}

					span {
						&:not(.cart_count) {
							display: none;
						}
					}
				}

				@include media(small) {
					a {
						> svg {
							width: 20px;
						}

						span {
							&:not(.cart_count) {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}


// **---------------------------------------------------**
// -------------------------------------------------------
// .m-LegalAge BEGIN
.c-ModalAge {
    
	// main styles are in global.scss of korkbase-template

    // __backdrop
    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 7000;

		&:before {
			content: '';
			background: url('../img/menu-bg.jpg') center center!important;
			background-size: cover!important;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: auto;
			transition: all ease-out .3s;
		}

		&:after {
			content:'';
			background: $splashColor;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
    }
	
	&__header {
		&__logo {
			svg {
				width: 250px;
				height: auto;
				max-height: 150px;
			}

			svg, svg path {
				fill: $splashTextColor;
			}
		}
	}

	&__content {
		margin-top: 50px;

		&__inner {
			&--notLegal {
				display: none;

				p {
					color: $splashTextColor;
				}
			}
		}
	}

	&__title {
		color: $splashTextColor;
		text-transform: none;
	}

	&__buttons {
		margin-top: 50px;
		.btn {
			margin: 0 5px;
		}
	}

	&__languages {
		margin-top: 75px;
		display: flex;
		justify-content: center;

		li {
			font-size: 14px;
			text-transform: uppercase;
			padding: 0 20px;

			a {
				font-size: 14px;
				text-transform: uppercase;
				color: $splashTextColor;

				&:hover, &:active, &:focus {
					color: rgba($splashTextColor, 0.5);
				}
			}

			&.active {
				color: rgba($splashTextColor, 0.5);
			}

			&:not(:first-child) {
				position: relative;
				
				&:before {
					content:'';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					background: $activeColor;
					width: 1px;
					height: 100%;
				}
			}
		}
	}

	&__form {
		&__title {
			color: $splashTextColor;
		}
		
		.form-element {
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media(XSmax) {
		&__header {
			&__logo {
				svg {
					width: 180px;
					max-height: 120px;
				}
			}
		}
		&__title {
			font-size: 18px;
		}
		
		&__form {
			&__title {
				font-size: 14px;
			}
		}
	}
}
// .m-LegalAge END
// -------------------------------------------------------
// **---------------------------------------------------**